import {
  Row,
  Col,
  Card,
  Pagination,
  Tooltip,
  Button,
  Select,
  Drawer,
  Modal,
  Input,
  Form,
  message,
  DatePicker,
  PaginationProps,
  Upload,
  Spin
} from "antd";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { BtnCellRenderer } from "./button";
import { Document } from "./icons/documents";
import { Employee } from "./icons/employee";
import { Activerequest } from "./icons/activerequests";
import { Enach } from "./icons/enach";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  enach,
  updateBulkAppraised,
  invokeArthmateStateMachine,
  getUsers,
  updateBulkLoanAssignUser,
  createExport,
  esign,
  getExportForEnachEsign,
  exportZipFiles,
  getLoanMetricsRows,
  bankValidate,
  createComplianceExport,
  getChargeMetricsRows,
  createOnboardingExport,
} from "../utitlities/request";
import {
  AlignCenterOutlined,
  DoubleLeftOutlined,
  DownloadOutlined,
  RetweetOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { MainReducer } from "../types";
import { DocumentsVerified } from "./icons/documentsVerified";
import { EnachGenerated } from "./icons/enachgenerated";
import { Esign } from "./icons/esign";
import { CSVLink } from "react-csv";
import {
  abbstatus,
  AppraisalActualStatus,
  AppraisalStatus,
  enach_status,
  esign_status,
  nbfc_status,
  bank_status,
  client_name,
  esanction_status
} from "../utitlities/status";
import { fetchNbfc, reset } from "../state/action-creators";
import {
  DynamicList,
  DynamicMandateSignature,
} from "../utitlities/filteroptions";
import { DuplicateLoan } from "./icons/duplicateloan";
import { ActiveLoan } from "./icons/activeloan";
import { LoanClosed } from "./icons/loanClosed";
import AgGridComponent from "./common/AgGridComponent";
import CustomDropdownFilter from "./common/customSelectFilter";
import { config } from "../config";
import {
  setAppraisalApiParams,
  getAppraisalList,
} from "../state/action-creators";
import { ShowDetailsIconAppraisal } from "./showDetailsIconAppraisal";
import { appraisalApiParamsInitState } from "../state/reducers/appraisalReducer";
import '../styles/buttons.css';

type RequiredMark = boolean | "optional";
let setTimeoutId: any;

export default function Appraisal() {
  const gridApiRef = useRef<any>(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [height, setHeight] = useState(window.innerHeight);
  const [states, setStates] = useState<any>(null);
  const [optionUser, setOptionUser] = useState("");
  const [isDrawer, setIsDrawer] = useState(false);
  const { Option } = Select;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [processingFee, setProcessingFee] = useState("350");
  const [interestRate, setInterestRate] = useState("12");
  const [type, setType] = useState("weekly");
  const [approvedAmount, setApprovedAmount] = useState("3500");
  const [period, setPeriod] = useState("12");
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("");

  const [company, setCompany] = useState("");
  const [loading, setLoading] = useState(false);
  const [gridLoader, setgridLoader] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<any>();
  const [pageSize, setPageSize] = useState(
    appraisalApiParamsInitState?.pageSize || 50
  );
  const [bulkUpdateStatus, setBulkUpdateStatus] = useState("");

  const { RangePicker } = DatePicker;
  const todaysDate = new Date().toISOString().substring(0, 10);
  const [disbursal_date, SetDisbursalDate] = useState("");
  const [status_last_updated_at, SetChangedDate] = useState(todaysDate);
  const [exportUIRows, setExportUIRows] = useState<any>([]);
  const [exportLoanMetrics, setExportLoanMetrics] = useState<any>([]);
  const [exportChargeMetrics, setexportChargeMetrics] = useState<any>([]);

  const defaultColDef = {
    flex: 1,
    resizable: true,
  };
  const dispatch = useDispatch();
  const appraisalApiParams = useSelector(
    (state: any) => state?.appraisal?.appraisalApiParams
  );
  const filterData = appraisalApiParams?.filterData;
  const filterModel = appraisalApiParams?.filterModel;
  const sortModel = appraisalApiParams?.sortModel;

  const [filters, setFilters] = useState({
    search: filterData?.search || '',
    status: filterData?.status || '',
    start_date: filterData?.start_date || null,
    end_date: filterData?.end_date || null,
  });

  useEffect(() => {
    setFilters({
      search: filterData?.search,
      status: filterData?.status,
      start_date: filterData?.start_date,
      end_date: filterData?.end_date,
    });
  }, [filterData]);

  const [compliance_start_date, setComplianceStartDate] = useState(filterData?.start_date);
  const [compliance_end_date, setComplianceEndDate] = useState(filterData?.end_date);
  const [loanMetricsReportDates, setLoanMetricsReportDates] = useState([
    filterData?.start_date, filterData?.end_date
  ]);
  const [chargeReportDates, setchargeReportDates] = useState([
    filterData?.start_date, filterData?.end_date
  ]);

  const appraisalList = useSelector(
    (state: any) => state?.appraisal?.appraisalApiResponse?.response
  );
  const appraisalApiLoader = useSelector(
    (state: any) => state?.appraisal?.appraisalApiResponse?.loading
  );

  const propsNewDocument = {
    name: "file",
    action: `${config.base_url}/utils/upload/bouncedLoans`,
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const uploadChargeSheet = {
    name: "file",
    action: `${config.base_url}/utils/upload/createCharge`,
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const propsNewDocumentDisbursed = {
    name: "file",
    action: `${config.base_url}/utils/upload/active`,
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token") as string)}`
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const propsNewDocumentArthmate = {
    name: "file",
    action: `${config.base_url}/utils/upload/arthmate`,
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const onRequiredTypeChange = ({
    requiredMarkValue,
  }: {
    requiredMarkValue: RequiredMark;
  }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDrawer = () => {
    setBulkUpdateStatus("");
    SetDisbursalDate("");
    SetChangedDate(todaysDate);
    setCompany("");
    setOptionUser("");
    setIsDrawer(true);
  };
  const closeDrawer = () => {
    setIsDrawer(false);
  };
  function updateDimension() {
    setHeight(window.innerHeight);
  }

  const nbfcstatus = useSelector((state: MainReducer) => {
    return state.loan.nbfc.status;
  });

  const nbfc = useSelector((state: MainReducer) => {
    return state.loan.nbfc.body;
  });

  const deSelectAllRow = () => {
    if (gridApiRef?.current) {
      gridApiRef?.current?.deselectAll();
    }
    setSelectedRows([]);
  };

  useEffect(() => {
    setCompanyOptions(nbfc);
  }, [nbfcstatus, nbfc]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateDimension);
    updateDimension();
    return () => window.removeEventListener("resize", updateDimension);
  }, []);

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
  };

  useEffect(() => {
    async function fetchUserApi() {
      const users = await getUsers();
      setUsers(users.filter((user: any) => {
        return user.role !== "deactivated"
      }));
    }
    fetchUserApi();
    dispatch(reset());
    dispatch(fetchNbfc());
    const user = JSON.parse(localStorage?.getItem("user") || "");
    setRole(user?.role);
    setStates(AppraisalStatus);
    const _appraisalApiParams = localStorage?.getItem("APPRAISAL_API_PARAMS")
      ? // @ts-ignore
      JSON.parse(localStorage?.getItem("APPRAISAL_API_PARAMS"))
      : "";
    if (_appraisalApiParams) {
      dispatch(
        setAppraisalApiParams({
          ..._appraisalApiParams,
          page: 1,
        })
      );
      setPageSize(_appraisalApiParams?.pageSize);
      gridApiRef?.current?.setFilterModel(_appraisalApiParams?.filterModel);
      gridApiRef?.current?.setSortModel(_appraisalApiParams?.sortModel);
    }
  }, [dispatch]);

  useEffect(() => {
    if (gridApiRef?.current) {
      if (appraisalApiLoader) {
        gridApiRef?.current.showLoadingOverlay();
      } else {
        gridApiRef?.current.hideOverlay();
      }
    }
  }, [appraisalApiLoader]);

  useEffect(() => {
    if (setTimeoutId) clearTimeout(setTimeoutId);
    setTimeoutId = setTimeout(() => {
      localStorage.setItem(
        "APPRAISAL_API_PARAMS",
        JSON.stringify(appraisalApiParams)
      );
      let request = {
        page: appraisalApiParams?.page,
        limit: appraisalApiParams?.pageSize,
        ...filterData,
        userid: "all",
      };
      for (const [key, value] of Object.entries(filterModel || {})) {
        let _value;
        // @ts-ignore
        if (value?.filterType === "date") {
          // @ts-ignore
          _value = value?.dateFrom;
        } else if (
          key === "nbfc_status" ||
          key === "is_bank_account_verified" ||
          key === "avg_balance" ||
          key === "user_name" ||
          key === "esign_status" ||
          key === "enach_status" ||
          key === "sanction_status" ||
          key === "client_name" ||
          key === "ops_username"
        ) {
          // @ts-ignore
          _value = value?.type;
        } else {
          // @ts-ignore
          _value = value?.filter;
        }
        // @ts-ignore
        request[key] = _value;
      }
      for (const _sort of sortModel || []) {
        // @ts-ignore
        request["sort_key"] = _sort?.colId;
        // @ts-ignore
        request["sort_order"] = _sort?.sort;
      }
      if (!request?.status) {
        request["status"] = [...AppraisalActualStatus];
      }
      dispatch(getAppraisalList(request));
    }, 500);
  }, [appraisalApiParams, dispatch, filterData, filterModel, sortModel]);

  // const onFilterInputChangeHandler = (e: any) => {
  //   const { name, value } = e?.target;
  //   gridApiRef?.current?.setFilterModel(null);
  //   gridApiRef?.current?.setSortModel(null);
  //   gridApiRef?.current?.deselectAll();
  //   setTimeout(() => {
  //     dispatch(
  //       setAppraisalApiParams({
  //         ...appraisalApiParams,
  //         filterData: {
  //           ...appraisalApiParams?.filterData,
  //           [name]: value,
  //         },
  //         filterModel: null,
  //         sortModel: null,
  //       })
  //     );
  //   }, 0);
  //   setSelectedRows([]);
  // };

  const onFilterInputChangeHandlerSearch = (e: any) => {
    let { name, value } = e.target;
    value = name == "status" && value == "All status" ? "" : value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const applyFilter = () => {
    try {
      setgridLoader(true);
      gridApiRef?.current?.setFilterModel(null);
      gridApiRef?.current?.setSortModel(null);
      gridApiRef?.current?.deselectAll();
      setTimeout(() => {
        dispatch(
          setAppraisalApiParams({
            ...appraisalApiParams,
            filterData: {
              ...appraisalApiParams?.filterData,
              ...filters,
            },
            filterModel: null,
            sortModel: null,
          })
        );
      }, 0);
      setSelectedRows([]);
    } catch (error) {
      console.log(error);
    } finally {
      setgridLoader(false);
    }
  };

  const onFilterDateChange = (dates: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: dates ? moment(dates[0]).format("YYYY-MM-DD") : null,
      end_date: dates ? moment(dates[1]).format("YYYY-MM-DD") : null,
    }));
  };

  const onComplianceDateChange = (dates: any) => {
    if (dates) {
      setComplianceStartDate(moment(dates[0]).format("YYYY-MM-DD"))
      setComplianceEndDate(moment(dates[1]).format("YYYY-MM-DD"))
    }
  };
  const onMetricDateChange = (dates: any) => {
    if (dates) {
      setTimeout(() => {
        setLoanMetricsReportDates([moment(dates[0]).format("YYYY-MM-DD"), moment(dates[1]).format("YYYY-MM-DD")]);
      }, 0)
    }
  };

  const onchargeDateChange = (dates: any) => {
    if (dates) {
      setTimeout(() => {
        setchargeReportDates([moment(dates[0]).format("YYYY-MM-DD"), moment(dates[1]).format("YYYY-MM-DD")]);
      }, 0)
    }
  };

  const generateMetricsReport = async function (dates: Array<any>) {
    try {
      setLoading(true);
      const payload = {
        start_date: dates[0],
        end_date: dates[1]
      }
      const result = await getLoanMetricsRows(payload);
      let metricsCSVData: any = [];
      result.forEach((element: any) => {
        let hashNew: any = {};
        hashNew["Borrower Id"] = element["borrower_id"];
        hashNew["Client Emp Id"] = element["client_employee_id"];
        hashNew["Full Name"] = element["full_name"];
        hashNew["Mobile"] = element["contact_no"];
        hashNew["Company Name"] = element["company_name"];
        hashNew["Department"] = element["department"];
        hashNew["Loan Request Id"] = element["request_id"];
        hashNew["Request Status"] = element["request_status"];
        hashNew["Request Created Date"] = element["request_date"];
        hashNew["User Category"] = element["user_status"];
        hashNew["Valid/Invalid"] = element["valid_invalid"];
        metricsCSVData.push(hashNew);
      });
      setExportLoanMetrics(metricsCSVData);
    } catch (error) {
      message.error('Error, Please try again later')
    }
  }

  const generateChargeReport = async function (dates: Array<any>) {
    try {
      setLoading(true);
      const payload = {
        start_date: dates[0],
        end_date: dates[1]
      }
      const result = await getChargeMetricsRows(payload);
      let metricsCSVData: any = [];
      metricsCSVData = []
      result.forEach((element: any) => {
        let insertObject: any = {}
        insertObject['charge_date'] = moment(element.charge_date).format("YYYY-MM-DD")
        insertObject['vendor'] = element.vendor
        insertObject['mandate_id'] = element.mandate_id
        insertObject['loan_id'] = element.loan_id
        insertObject['company'] = element.company
        insertObject['amount'] = element.amount
        insertObject['status'] = element.status

        metricsCSVData.push(insertObject);
      });
      setexportChargeMetrics(metricsCSVData);
    } catch (error) {
      console.log("error is ", error)
      message.error('Error, Please try again later')
    }
  }

  const handleFilterChanged = () => {
    const filterModel = gridApiRef?.current?.getFilterModel();
    gridApiRef?.current?.deselectAll();
    dispatch(
      setAppraisalApiParams({
        ...appraisalApiParams,
        filterModel,
      })
    );
    setSelectedRows([]);
  };

  const handleSortChanged = (event: any) => {
    const sortModel = gridApiRef?.current?.getSortModel();
    gridApiRef?.current?.deselectAll();
    dispatch(
      setAppraisalApiParams({
        ...appraisalApiParams,
        sortModel,
      })
    );
    setSelectedRows([]);
  };

  const handleRowDataChanged = (event: any) => {
    setTimeout(() => {
      gridApiRef?.current?.setFilterModel(filterModel);
      gridApiRef?.current?.setSortModel(sortModel);
    }, 0);
  };

  const onRowSelectedHandle = (event: any) => {
    const isSelected = !!event?.node?.selected;
    let selectedRowData = event?.data;
    if (isSelected && event?.data) {
      // @ts-ignore
      setSelectedRows((current: Array) => [...current, selectedRowData]);
    } else {
      // @ts-ignore
      setSelectedRows((current: Array) => [
        ...current?.filter((obj: any) => {
          return obj?.borrower_id !== event?.data?.borrower_id;
        }),
      ]);
    }
  };

  const handleBulkUpdateStatusChange = (value: any) => {
    setBulkUpdateStatus(value);
  };

  const assignUser = async () => {
    if (!selectedRows?.length) {
      message.warning("Please select a row to proceed");
      return;
    }
    let loan_ids: any = [];
    selectedRows.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data = {
      loan_ids: loan_ids,
      user_id: Number(optionUser),
    };
    if (loan_ids.length === 0) {
      message.warning("Please select a row  to proceed");
    } else if (optionUser.length === 0) {
      message.warning("Please select an option  to proceed");
    } else {
      await updateBulkLoanAssignUser(data);
      // gridApiRef?.current?.setFilterModel(null);
      // gridApiRef?.current?.setSortModel(null);
      gridApiRef?.current?.deselectAll();
      setTimeout(() => {
        dispatch(
          setAppraisalApiParams({
            ...appraisalApiParams,
            // filterModel: null,
            // sortModel: null,
            page: 1,
          })
        );
      }, 0);
      setSelectedRows([]);
      closeDrawer();
      message.success("Successfully done.");
    }
  };

  const onButtonClick = async () => {
    if (!selectedRows?.length) {
      message.warning("Please select a row to proceed");
      return;
    }
    let loan_ids: any = [];
    selectedRows.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data: any = {
      loan_ids: loan_ids,
      status: bulkUpdateStatus,
    };
    if (bulkUpdateStatus === "COMPLETE") {
      data["disbursal_date"] = disbursal_date;
      data["status_last_updated_at"] = status_last_updated_at;
    }
    if (bulkUpdateStatus !== "COMPLETE") {
      data["status_last_updated_at"] = status_last_updated_at;
    }
    if (loan_ids.length === 0) {
      message.warning("Please select a row  to proceed");
    } else if (bulkUpdateStatus.length === 0) {
      message.warning("Please select a row  to proceed");
    } else if (bulkUpdateStatus === "COMPLETE" && !disbursal_date) {
      message.warning("Please select a disbursal date");
    } else {
      await updateBulkAppraised(data);
      // gridApiRef?.current?.setFilterModel(null);
      // gridApiRef?.current?.setSortModel(null);
      gridApiRef?.current?.deselectAll();
      setTimeout(() => {
        dispatch(
          setAppraisalApiParams({
            ...appraisalApiParams,
            // filterModel: null,
            // sortModel: null,
            page: 1,
          })
        );
      }, 0);
      setSelectedRows([]);
      closeDrawer();
      message.success("Successfully done.");
    }
  };

  const onArthmateButtonClick = async () => {
    if (!selectedRows?.length) {
      message.warning("Please select a row to proceed");
      return;
    }
    let loan_ids: any = [];
    selectedRows?.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data = {
      loan_ids: loan_ids,
    };
    await invokeArthmateStateMachine(data);
    // gridApiRef?.current?.setFilterModel(null);
    // gridApiRef?.current?.setSortModel(null);
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setAppraisalApiParams({
          ...appraisalApiParams,
          // filterModel: null,
          // sortModel: null,
          page: 1,
        })
      );
    }, 0);
    setSelectedRows([]);
    closeDrawer();
    message.success("Successfully done.");
  };

  const onPennyDropButtonClick = async () => {
    if (!selectedRows?.length) {
      message.warning("Please select a row to proceed");
      return;
    }
    let loan_ids: any = [];
    selectedRows?.forEach((element: any) => {
      loan_ids.push(Number(element["request_id"]));
    });
    await bankValidate(loan_ids);
    // gridApiRef?.current?.setFilterModel(null);
    // gridApiRef?.current?.setSortModel(null);
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setAppraisalApiParams({
          ...appraisalApiParams,
          // filterModel: null,
          // sortModel: null,
          page: 1,
        })
      );
    }, 0);
    setSelectedRows([]);
    closeDrawer();
    message.success("Successfully done.");
  };

  const eNachClickHandler = async () => {
    if (!selectedRows?.length) {
      message.warning("Please select a row to proceed");
      return;
    }
    let loan_ids: any = [];
    selectedRows?.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data = {
      loan_ids: loan_ids,
    };
    await enach(data);
    // gridApiRef?.current?.setFilterModel(null);
    // gridApiRef?.current?.setSortModel(null);
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setAppraisalApiParams({
          ...appraisalApiParams,
          // filterModel: null,
          // sortModel: null,
          page: 1,
        })
      );
    }, 0);
    setSelectedRows([]);
    closeDrawer();
    message.success("Successfully done.");
  };

  const eSignClickHandler = async () => {
    if (!selectedRows?.length) {
      message.warning("Please select a row to proceed");
      return;
    }
    let loan_ids: any = [];
    selectedRows?.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data = {
      loan_ids: loan_ids,
    };
    await esign(data);
    // gridApiRef?.current?.setFilterModel(null);
    // gridApiRef?.current?.setSortModel(null);
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setAppraisalApiParams({
          ...appraisalApiParams,
          // filterModel: null,
          // sortModel: null,
          page: 1,
        })
      );
    }, 0);
    setSelectedRows([]);
    closeDrawer();
    message.success("Successfully done.");
  };

  const filterOptionsEnach: any = DynamicMandateSignature(enach_status);
  const filterOptionsEsign: any = DynamicMandateSignature(esign_status);
  const filterOptionsEsanction: any = DynamicMandateSignature(esanction_status);
  const filterOptionsNbfcStatus: any = DynamicMandateSignature(nbfc_status);
  const filterOptionsClientName: any = DynamicMandateSignature(client_name);
  const filterOptionsBankStatus: any = DynamicMandateSignature(bank_status);
  const filterOptionsAbb: any = DynamicMandateSignature(abbstatus);

  const dateFilterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2020,
    maxValidYear: 2025,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    buttons: ["apply", "reset", "cancel"],
    closeOnApply: true,
    filterOptions: ["equals"],
    maxNumConditions: 1,
    trimInput: true,
  };

  const filterOptionsAssigned: string[] = users
    .map((element: any) => element.user_name)
    .sort((a, b) => a.localeCompare(b));

  const resetAllFilters = () => {
    gridApiRef.current.setFilterModel(null);
    gridApiRef.current.setSortModel(null);
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setAppraisalApiParams({
          ...appraisalApiParamsInitState,
          pageSize: appraisalApiParams?.pageSize,
          filterData: {
            ...appraisalApiParamsInitState?.filterData,
            start_date: appraisalApiParams?.filterData?.start_date,
            end_date: appraisalApiParams?.filterData?.end_date,
          },
        })
      );
    }, 0);
    setSelectedRows([]);
    message.info("Filters Reset Successfully");
  };

  function parseDisbursalDate(params: any) {
    if (params.data.disbursal_date) {
      return moment(params.data.disbursal_date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }

  function parseRequestDate(params: any) {
    if (params.data.request_created_date) {
      return moment(params.data.request_created_date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }

  function parseupdatedDate(params: any) {
    if (params.data.status_last_updated_at) {
      return moment(params.data.status_last_updated_at).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }

  function AssignedTo(params: any) {
    if (params.data.user_name) {
      return params.data.user_name;
    } else {
      return "";
    }
  }

  function AverageBankBalance(params: any) {
    if (params.data.avg_balance) {
      return params.data.avg_balance;
    } else {
      return "UNKNOWN";
    }
  }

  function getNbfcStatus(params: any) {
    if (params.data.nbfc_status) {
      return params.data.nbfc_status;
    } else {
      return "NOT_CREATED";
    }
  }
  function AssignedToOps(params: any) {
    if (params.data.ops_username) {
      return params.data.ops_username;
    } else {
      return "";
    }
  }

  function getBankStatus(params: any) {
    if (params.data.is_bank_account_verified && params.data.is_bank_account_verified == 0) {
      return "NOT_CHECKED";
    }
    else if (params.data.is_bank_account_verified && params.data.is_bank_account_verified == 1) {
      return "VALID";
    }
    else if (params.data.is_bank_account_verified && params.data.is_bank_account_verified == 2) {
      return "INVALID";
    }
    else {
      return "NOT_PRESENT";
    }
  }
  const columnDefs = [
    {
      field: "ops_id",
      headerName: "Loan Id",
      pinned: "left",
      headerCheckboxSelectionFilteredOnly: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "pan",
      headerName: "PAN",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      width: 120,
      minWidth: 120,
      maxWidth: 120,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "full_name",
      headerName: "Full Name",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      pinned: "left",
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "mobile_number",
      headerName: "Mobile",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      cellStyle: { fontSize: "12px" },
      suppressSizeToFit: true,
      width: 120,
      minWidth: 120,
      maxWidth: 120,
    },
    {
      field: "whatsapp_no",
      headerName: "Whatsapp",
      cellStyle: { fontSize: "12px" },
      suppressSizeToFit: true,
      width: 120,
      minWidth: 120,
      maxWidth: 120,
    },
    {
      headerName: "Requested Amount",
      field: "requested_amount",
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      suppressSizeToFit: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      cellStyle: { fontSize: "12px" },
    },
    {
      headerName: "Request Id",
      field: "request_ops_id",
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
      sortable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
    },
    {
      headerName: "Suggested Amount",
      field: "suggested_amount_requested",
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      suppressSizeToFit: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      cellStyle: { fontSize: "12px" },
    },
    {
      headerName: "Third Party Status",
      field: "third_party_status",
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      suppressSizeToFit: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      cellStyle: { fontSize: "12px" },
      valueFormatter: (params: any) => (params.value ? "Yes" : "No"),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 140,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "nbfc_status",
      headerName: "Nbfc Status",
      minWidth: 160,
      filter: true,
      valueGetter: getNbfcStatus,
      filterParams: {
        filterOptions: filterOptionsNbfcStatus,
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        trimInput: true,
        maxNumConditions: 1,
      },
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },

    {
      field: "is_bank_account_verified",
      headerName: "Bank Status",
      minWidth: 160,
      filter: true,
      valueGetter: getBankStatus,
      filterParams: {
        filterOptions: filterOptionsBankStatus,
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        trimInput: true,
        maxNumConditions: 1,
      },
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      headerName: "Client Name",
      field: "client_name",
      minWidth: 160,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterOptionsClientName,
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        trimInput: true,
        maxNumConditions: 1,
      },
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "request_status_comment",
      headerName: "Loan Status Comment",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        maxNumConditions: 1,
        trimInput: true,
      },
      minWidth: 180,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "ops_request_status_comment",
      headerName: "Request Status Comment",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        maxNumConditions: 1,
        trimInput: true,
      },
      minWidth: 200,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      headerName: "Approved Amount",
      field: "approved_amount",
      minWidth: 161,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        maxNumConditions: 1,
        trimInput: true,
      },
      sortable: false,
    },
    // {
    //   headerName: "ABB",
    //   field: "avg_balance",
    //   filter: "agNumberColumnFilter",
    //   minWidth: 140,
    //   valueGetter: AverageBankBalance,
    //   suppressSizeToFit: true,
    //   cellStyle: { fontSize: "12px" },
    //   filterParams: {
    //     filterOptions: filterOptionsAbb,
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     maxNumConditions: 1,
    //     trimInput: true,
    //   },
    //   sortable: false,
    // },
    {
      field: "user_name",
      headerName: "Loan Assigned To",
      valueGetter: AssignedTo,
      pinned: "right",
      cellStyle: { fontSize: "12px" },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filterFramework: CustomDropdownFilter,
      filterParams: {
        options: filterOptionsAssigned,
        buttons: ["apply", "reset", "cancel"],
        type: "loan",
        closeOnApply: true,
        trimInput: true,
      },
    },
    {
      field: "loan_id",
      headerName: "Details",
      pinned: "right",
      hide: role === "operations" ? true : false,
      suppressSizeToFit: true,
      cellRenderer: "ShowDetailsIconAppraisal",
      cellStyle: { fontSize: "12px" },
      width: 80,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      field: "nbfc_name",
      headerName: "NBFC",
      sortable: false,
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
      width: 140,
      maxWidth: 140,
      suppressSizeToFit: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        maxNumConditions: 1,
        trimInput: true,
      },
    },
    {
      field: "esign_status",
      headerName: "E-Signature",
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
      width: 140,
      maxWidth: 140,
      filterParams: {
        filterOptions: filterOptionsEsign,
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        maxNumConditions: 1,
        trimInput: true,
      },
      filter: true,
    },
    {
      field: "enach_status",
      headerName: "E-Nach",
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
      width: 140,
      maxWidth: 140,
      filterParams: {
        filterOptions: filterOptionsEnach,
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        maxNumConditions: 1,
        trimInput: true,
      },
      filter: true,
    },
    {
      field: "sanction_status",
      headerName: "E-Sanction",
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
      width: 140,
      maxWidth: 140,
      filterParams: {
        filterOptions: filterOptionsEsanction,
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        maxNumConditions: 1,
        trimInput: true,
      },
      filter: true,
    },
    {
      field: "verified_ref_count",
      headerName: "Reference Verified",
      cellStyle: { fontSize: "12px" },
      minWidth: 160,
      filter: "agNumberColumnFilter",
      sortable: false,
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        maxNumConditions: 1,
        trimInput: true,
      },
    },
    {
      headerName: "Disbursal Date",
      field: "disbursal_date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      valueGetter: parseDisbursalDate,
      sortable: false,
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
    },

    {
      headerName: "Request Date",
      field: "request_created_date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      valueGetter: parseRequestDate,
      sortable: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
    },
    {
      headerName: "Status Updated",
      field: "status_last_updated_at",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      valueGetter: parseupdatedDate,
      sortable: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
    },
    {
      field: "ops_username",
      headerName: "Request Assigned to ",
      valueGetter: AssignedToOps,
      filter: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 170,
      filterFramework: CustomDropdownFilter,
      filterParams: {
        options: filterOptionsAssigned,
        buttons: ["apply", "reset", "cancel"],
        type: "request",
        closeOnApply: true,
        maxNumConditions: 1,
        trimInput: true,
      },
    },
  ];

  function onChangeUser(value: any) {
    setOptionUser(value);
  }

  const onChangePage = (page: number, pageSize?: number) => {
    if (page && pageSize) {
      dispatch(
        setAppraisalApiParams({
          ...appraisalApiParams,
          page,
          pageSize,
        })
      );
    }
  };

  async function extractZip() {
    if (!selectedRows?.length) {
      message.warning("Please select a row to proceed");
      return;
    }
    let data: any = [];
    selectedRows.forEach((element: any) => {
      data.push(element["loan_id"]);
    });
    if (selectedRows.length > 50) {
      message.warning("Please select less than 50 rows!.");
      return;
    } else {
      await exportZipFiles(data);
      message.success("Export will be sent to your email!");
    }
  }

  const pageSizeBtnClickHandler = (e: any) => {
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setAppraisalApiParams({
          ...appraisalApiParams,
          filterModel: null,
          sortModel: null,
          page: 1,
          pageSize: Number(pageSize),
        })
      );
    }, 0);
    setSelectedRows([]);
    closeDrawer();
    message.success("Page size updated.");
  };

  async function getExportedData() {
    let result: Array<any> = [];
    try {
      if (!selectedRows?.length) {
        message.warning("Please select a row to proceed");
        return;
      }
      let data: any = [];
      selectedRows.forEach((element: any) => {
        data.push(element["ops_id"]);
      });
      const hash: any = await getExportForEnachEsign({ loan_ids: data });

      selectedRows.forEach((element: any) => {
        let hashNew: any = {};
        hashNew["Borrower Id"] = element["borrower_id"];
        hashNew["Amount"] = element["requested_amount"];
        hashNew["Approved Amount"] = element["approved_amount"];
        hashNew["Full Name"] = element["full_name"];
        hashNew["Mobile"] = element["mobile_number"];
        hashNew["WhatsApp"] = element["whatsapp_no"];
        hashNew["Loan Status Comment"] = element["request_status_comment"];
        hashNew["Request Status Comment"] =
          element["ops_request_status_comment"];
        hashNew["Loan Id"] = element["ops_id"];
        hashNew["Request Id"] = element["request_ops_id"];
        hashNew["Client Name"] = element["client_name"];
        hashNew["Reference Verified"] = element["verified_ref_count"];
        hashNew["Nbfc"] = element["nbfc_name"];
        hashNew["Nbfc Status"] = element["nbfc_status"] || "NOT_CREATED";
        hashNew["Duplicate"] = element["duplicate"];
        hashNew["Active"] = element["active"];
        hashNew["Status"] = element["status"];
        hashNew["Loan Closed Status"] = element["is_loan_closed"];
        hashNew["Esign"] = element["esign_status"];
        hashNew["Enach"] = element["enach_status"];
        hashNew["Disbursal Date"] = element["disbursal_date"];
        hashNew["UserName"] = element["user_name"];
        hashNew["Request Assigned To"] = element["ops_username"];
        hashNew["Esign Url"] = hash[element["ops_id"]]
          ? hash[element["ops_id"]][0]["sign_url"]
          : "";
        hashNew["Enach Url"] = hash[element["ops_id"]]
          ? hash[element["ops_id"]][0]["enach_url"]
          : "";
        result.push(hashNew);
      });
      setExportUIRows(result);
    } catch (err) {
      message.error("Failed to Export");
      setExportUIRows(result);
    }
  }

  useEffect(() => {
    if (exportUIRows.length > 0) {
      document.getElementById("download")?.click();
    }
  }, [exportUIRows]);

  useEffect(() => {
    if (exportLoanMetrics.length > 0) {
      document.getElementById("csvmetric")?.click();
    }
    setLoading(false);
  }, [exportLoanMetrics]);

  useEffect(() => {
    if (exportChargeMetrics.length > 0) {
      document.getElementById("chargemetric")?.click();
    }
    setLoading(false);
  }, [exportChargeMetrics]);

  const showTotal: PaginationProps["showTotal"] = (total) =>
    `Total Rows: ${total}`;

  return (
    <>
      <div style={{ backgroundColor: "#f0f0f0" }}>
        <Row
          style={{
            padding: "15px 15px 5px 15px",
          }}
        >
          <Col span={16}>
            <Row>
              {/* <Col
                span={5}
                style={{
                  paddingRight: "15px",
                }}
              >
                <Input
                  placeholder="Whatsapp No"
                  name="whatsapp_no"
                  value={filterData?.whatsapp_no}
                  onChange={onFilterInputChangeHandler}
                />
              </Col> */}
              <Col
                span={7}
                style={{
                  paddingRight: "15px",
                }}
              >
                <Select
                  value={filters?.status}
                  style={{ width: "100%" }}
                  placeholder="Please select a status"
                  onChange={(value: any) =>
                    onFilterInputChangeHandlerSearch({
                      target: {
                        name: "status",
                        value,
                      },
                    })
                  }
                >
                  {["", ...AppraisalActualStatus]?.map(
                    (element: any, index: number) => {
                      return (
                        <Option
                          value={element}
                          key={`${element
                            ?.split(" ")
                            .join("-")}-status-${index}`}
                        >
                          {element || "All status"}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Col>
              <Col span={6}
                style={{
                  paddingRight: "15px",
                }}>
                <RangePicker
                  format="DD-MM-YYYY"
                  onChange={onFilterDateChange}
                  value={[
                    moment(filters?.start_date),
                    moment(filters?.end_date),
                  ]}
                  defaultValue={[
                    moment(filterData?.start_date),
                    moment(filterData?.end_date),
                  ]}
                />
              </Col>
              <Col
                span={6}
                style={{
                  paddingRight: "15px",
                }}
              >
                <Input
                  placeholder="Search ..."
                  name="search"
                  value={filters.search}
                  onChange={onFilterInputChangeHandlerSearch}
                />
              </Col>
              <div className="loading-overlay">
                <Spin spinning={appraisalApiLoader} tip="Loading...">
                  <Col
                    span={3}
                    style={{
                      paddingRight: "15px",
                    }}
                  >
                    <Button onClick={applyFilter} className="antd-button">
                      Apply Filter
                    </Button>
                  </Col>
                </Spin>
              </div>
            </Row>
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="text"
              icon={<RetweetOutlined />}
              onClick={deSelectAllRow}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="antd-button"
            >
              Deselect Rows
            </Button>
            <Button
              type="text"
              icon={<DoubleLeftOutlined />}
              onClick={resetAllFilters}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="antd-button"
            >
              Reset Filters
            </Button>
            <Tooltip title="Click here for actions">
              <AlignCenterOutlined
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  padding: "0px 0px 0px 10px",
                }}
                onClick={showDrawer}
              />
            </Tooltip>
          </Col>
        </Row>

        <Drawer
          title="Actions"
          placement="right"
          onClose={closeDrawer}
          visible={isDrawer}
          width={500}
        >
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col>
              <h6 style={{ marginBottom: "5%" }}>Update Status</h6>
            </Col>
            <Col span={24}>
              <Select
                defaultValue="Please select an option"
                style={{ width: "100%", marginBottom: "2%" }}
                value={bulkUpdateStatus}
                onChange={handleBulkUpdateStatusChange}
              >
                {states?.map((element: any, index: number) => {
                  return (
                    <Option value={element} key={`${element}-${index}`}>
                      {element}
                    </Option>
                  );
                })}
              </Select>
              {bulkUpdateStatus === "" ? (
                <></>
              ) : bulkUpdateStatus === "COMPLETE" ? (
                <>
                  <h6 style={{ marginBottom: "5%", marginTop: "1%" }}>
                    Disbursal Date
                  </h6>
                  <Input
                    type="date"
                    value={disbursal_date}
                    onChange={(e) => {
                      SetDisbursalDate(e.target.value);
                    }}
                  ></Input>
                  <h6 style={{ marginBottom: "5%", marginTop: "3%" }}>
                    Changed At
                  </h6>
                  <Input
                    type="date"
                    value={status_last_updated_at}
                    onChange={(e) => {
                      SetChangedDate(e.target.value);
                    }}
                  ></Input>
                </>
              ) : (
                <>
                  <h6 style={{ marginBottom: "5%", marginTop: "2%" }}>
                    Changed At
                  </h6>
                  <Input
                    type="date"
                    value={status_last_updated_at}
                    onChange={(e) => {
                      SetChangedDate(e.target.value);
                    }}
                  ></Input>
                </>
              )}
            </Col>
            <Col style={{ marginTop: "5%" }}>
              <Button
                type="primary"
                style={{ backgroundColor: "#1B875A", border: 0 }}
                block
                onClick={() => {
                  onButtonClick();
                }}
                className="antd-button"
              >
                Bulk Update
              </Button>
            </Col>

            <Col span={24} style={{ marginTop: "5%" }}></Col>
          </Card>

          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col>
              <h6 style={{ marginBottom: "5%" }}>Page Size</h6>
            </Col>
            <Row>
              <Col span={18}>
                <Input
                  type="number"
                  name="pageSize"
                  value={pageSize}
                  onChange={(e: any) => {
                    setPageSize(e?.target?.value);
                  }}
                ></Input>
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  block
                  onClick={pageSizeBtnClickHandler}
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "#1B875A",
                    border: 0
                  }}
                  className="antd-button"
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col span={24} style={{ marginTop: "5%" }}>
              <Col>
                <h6 style={{ marginBottom: "5%" }}>Nbfc Trigger</h6>
              </Col>
              <Button
                block
                type="primary"
                style={{ backgroundColor: "#1B875A", border: 0 }}
                onClick={() => {
                  onArthmateButtonClick();
                }}
                className="antd-button"
              >
                Generate
              </Button>
            </Col>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col span={24} style={{ marginTop: "5%" }}>
              <Col>
                <h6 style={{ marginBottom: "5%" }}>Penny Drop Trigger</h6>
              </Col>
              <Button
                block
                type="primary"
                style={{ backgroundColor: "#1B875A", border: 0 }}
                onClick={() => {
                  onPennyDropButtonClick();
                }}
                className="antd-button"
              >
                Generate
              </Button>
            </Col>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col span={24} style={{ marginTop: "5%" }}>
              <Col>
                <h6 style={{ marginBottom: "5%" }}>Enach</h6>
              </Col>
              <Button
                block
                type="primary"
                style={{ backgroundColor: "#1B875A", border: 0 }}
                onClick={() => {
                  eNachClickHandler();
                }}
                className="antd-button"
              >
                Generate
              </Button>
            </Col>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col>
              <h6 style={{ marginBottom: "5%" }}>E-Signature</h6>
            </Col>
            <Button
              type="primary"
              style={{ backgroundColor: "#1B875A", border: 0 }}
              block
              onClick={() => {
                eSignClickHandler();
              }}
              className="antd-button"
            >
              Generate
            </Button>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Select
              defaultValue="Select Company"
              style={{ width: "100%", marginBottom: "2%" }}
              onChange={(value: any) => {
                setCompany(value);
              }}
            >
              {nbfcstatus && companyOptions?.length > 0
                ? companyOptions?.map((element: any, index: number) => {
                  return (
                    <Option
                      value={element?.id}
                      key={`${element?.name}-${index}`}
                    >
                      {element?.name}
                    </Option>
                  );
                })
                : ""}
            </Select>
            <Button
              type="primary"
              style={{ backgroundColor: "#1B875A", border: 0 }}
              block
              onClick={() => {
                if (!company) {
                  message.warning("Please choose a company");
                } else {
                  createExport(
                    company,
                    filterData?.start_date,
                    filterData?.end_date,
                    filterData?.status
                  );
                }
              }}
              className="antd-button"
            >
              Export Data
            </Button>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Select
              defaultValue="Select Company"
              style={{ width: "100%", marginBottom: "2%" }}
              onChange={(value: any) => {
                setCompany(value);
              }}
            >
              {nbfcstatus && companyOptions?.length > 0
                ? companyOptions?.map((element: any, index: number) => {
                  return (
                    <Option
                      value={element?.id}
                      key={`${element?.name}-${index}`}
                    >
                      {element?.name}
                    </Option>
                  );
                })
                : ""}
            </Select>
            <Button
              type="primary"
              style={{ backgroundColor: "#1B875A", border: 0 }}
              block
              onClick={() => {
                if (!company) {
                  message.warning("Please choose a company");
                } else {
                  createOnboardingExport(
                    company,
                    filterData?.start_date,
                    filterData?.end_date,
                    filterData?.status
                  );
                }
              }}
              className="antd-button"
            >
              Export Onboarding Data
            </Button>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <RangePicker
              style={{ marginBottom: "3%", width: "400px" }}
              format="DD-MM-YYYY"
              onChange={onComplianceDateChange}
              value={[
                moment(compliance_start_date),
                moment(compliance_end_date),
              ]}
              defaultValue={[
                moment(filterData?.start_date),
                moment(filterData?.end_date),
              ]}
            />
            <Button
              type="primary"
              block
              style={{ backgroundColor: "#1B875A", border: 0 }}
              onClick={() => {
                createComplianceExport(
                  compliance_start_date,
                  compliance_end_date
                );
              }}
              className="antd-button"
            >
              Export Compliance Data
            </Button>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col style={{ marginTop: "5%" }}>
              <CSVLink
                id="download"
                style={{ visibility: "hidden" }}
                data={exportUIRows}
              >
                Download me
              </CSVLink>

              <Button
                type="primary"
                block
                style={{ backgroundColor: "#1B875A", border: 0 }}
                onClick={async () => {
                  if (selectedRows?.length === 0) {
                    message.warning("Please select a row!");
                  } else {
                    await getExportedData();
                  }
                }}
                className="antd-button"
              >
                Export Selected Rows
              </Button>
            </Col>
          </Card>
          <Spin spinning={loading} tip="Loading...">
          </Spin>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col>
              <h6 style={{ marginBottom: "5%" }}>Loan Request Status Report</h6>
            </Col>
            <Col>
              <RangePicker
                format="DD-MM-YYYY"
                onChange={onMetricDateChange}
                value={[
                  moment(loanMetricsReportDates[0]),
                  moment(loanMetricsReportDates[1]),
                ]}
                defaultValue={[
                  moment(filterData?.start_date),
                  moment(filterData?.end_date),
                ]}
              />
            </Col>
            <Col style={{ marginTop: "5%" }}>
              <CSVLink
                id="csvmetric"
                style={{ visibility: "hidden" }}
                data={exportLoanMetrics}
                filename={"loanMetrics.csv"}
              >
                Download me
              </CSVLink>

              <Button
                type="primary"
                style={{ backgroundColor: "#1B875A", border: 0 }}
                onClick={async () => {
                  generateMetricsReport(loanMetricsReportDates);
                }}
                className="antd-button"
              >
                Generate
              </Button>
            </Col>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col>
              <h6 style={{ marginBottom: "5%" }}>Charges Status Report</h6>
            </Col>
            <Col>
              <RangePicker
                format="DD-MM-YYYY"
                onChange={onchargeDateChange}
                value={[
                  moment(chargeReportDates[0]),
                  moment(chargeReportDates[1]),
                ]}
                defaultValue={[
                  moment(filterData?.start_date),
                  moment(filterData?.end_date),
                ]}
              />
            </Col>
            <Col style={{ marginTop: "5%" }}>
              <CSVLink
                id="chargemetric"
                style={{ visibility: "hidden" }}
                data={exportChargeMetrics}
                filename={"chargeReport.csv"}
              >
                Download me
              </CSVLink>

              <Button
                type="primary"
                style={{ backgroundColor: "#1B875A", border: 0 }}
                onClick={async () => {
                  generateChargeReport(chargeReportDates);
                }}
                className="antd-button"
              >
                Generate
              </Button>
            </Col>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col style={{ marginTop: "5%" }}>
              <CSVLink
                id="download"
                style={{ visibility: "hidden" }}
                data={exportUIRows}
              >
                Download me
              </CSVLink>
              <Button
                type="primary"
                style={{ backgroundColor: "#1B875A", border: 0 }}
                block
                onClick={async () => {
                  if (selectedRows?.length === 0) {
                    message.warning("Please select a row!");
                  } else {
                    await extractZip();
                  }
                }}
                className="antd-button"
              >
                Export Documents As Zip
              </Button>
            </Col>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Row justify="space-between" align="middle">
              <Col>
                <h6 style={{ marginBottom: "2%" }}>Upload Bounced Loan Sheet</h6>
              </Col>
              <Col>
                <Tooltip title="Sample file currently not available.">
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#FFFFFF", border: 0, color: "#000000" }}
                    // onClick={() => window.location.href = 'https://s3-url-to-download-template'}
                    icon={<DownloadOutlined />}
                    className="antd-button"
                  />
                </Tooltip>
              </Col>
            </Row>
            <Col span={24}>
              <Upload {...propsNewDocument}>
                <Button type="primary" style={{ width: "235%", backgroundColor: "#1B875A", border: 0 }} icon={<UploadOutlined />} className="antd-button">
                  Upload Document
                </Button>
              </Upload>
            </Col>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Row justify="space-between" align="middle">
              <Col>
                <h6 style={{ marginBottom: "2%" }}>Upload Charged Loan Sheet</h6>
              </Col>
              <Col>
                <Tooltip title="Download the template file for this job. The uploader will accept data in the given format only.">
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#FFFFFF", border: 0, color: "#000000" }}
                    onClick={() => window.location.href = 'https://production-attachment.s3.ap-south-1.amazonaws.com/Charge+Sample+Sheet.xlsx'}
                    icon={<DownloadOutlined />}
                    className="antd-button"
                  />
                </Tooltip>
              </Col>
            </Row>
            <Col span={24}>
              <Upload {...uploadChargeSheet}>
                <Button type="primary" style={{ width: "235%", backgroundColor: "#1B875A", border: 0 }} icon={<UploadOutlined />} className="antd-button">
                  Upload Document
                </Button>
              </Upload>
            </Col>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Row justify="space-between" align="middle">
              <Col>
                <h6 style={{ marginBottom: "2%" }}>Upload disbursed Sheet</h6>
              </Col>
              <Col>
                <Tooltip title="Download the template file for this job. The uploader will accept data in the given format only.">
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#FFFFFF", border: 0, color: "#000000" }}
                    onClick={() => window.location.href = 'https://production-attachment.s3.ap-south-1.amazonaws.com/Disbursed+Sample+Sheet.xlsx'}
                    icon={<DownloadOutlined />}
                    className="antd-button"
                  />
                </Tooltip>
              </Col>
            </Row>
            <Col span={24}>
              <Upload {...propsNewDocumentDisbursed}>
                <Button type="primary" style={{ width: "235%", backgroundColor: "#1B875A", border: 0 }} icon={<UploadOutlined />} className="antd-button">
                  Upload Document
                </Button>
              </Upload>
            </Col>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Row justify="space-between" align="middle">
              <Col>
                <h6 style={{ marginBottom: "2%" }}>Upload arthmate Sheet</h6>
              </Col>
              <Col>
                <Tooltip title="Download the template file for this job. The uploader will accept data in the given format only.">
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#FFFFFF", border: 0, color: "#000000" }}
                    onClick={() => window.location.href = 'https://production-attachment.s3.ap-south-1.amazonaws.com/Arthmate+Sample+Sheet.xlsx'}
                    icon={<DownloadOutlined />}
                    className="antd-button"
                  />
                </Tooltip>
              </Col>
            </Row>
            <Col span={24}>
              <Upload {...propsNewDocumentArthmate}>
                <Button type="primary" style={{ width: "235%", backgroundColor: "#1B875A", border: 0 }} icon={<UploadOutlined />} className="antd-button">
                  Upload Document
                </Button>
              </Upload>
            </Col>
          </Card>
          {role === "doc_check" ||
            role === "appraisal" ||
            role === "admin" ||
            role === "super_admin" ||
            role === "operations" ? (
            <>
              <Card style={{ padding: "", marginTop: "4%" }}>
                <Col>
                  <h6 style={{ marginBottom: "5%" }}>Assign User</h6>
                </Col>
                <Col span={24}>
                  <Select
                    defaultValue="Please select an option"
                    showSearch
                    style={{ width: "100%" }}
                    onChange={onChangeUser}
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {users.map((element: any, index: number) => {
                      return (
                        <Option
                          value={element["id"]}
                          key={`${element["user_name"]}-${index}`}
                        >
                          {element["user_name"]}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col style={{ marginTop: "5%" }}>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#1B875A", border: 0 }}
                    block
                    onClick={() => {
                      assignUser();
                    }}
                    className="antd-button"
                  >
                    Assign
                  </Button>
                </Col>
              </Card>
            </>
          ) : (
            ""
          )}
        </Drawer>
        <Row>
          <Col span={24}>
            <div
              className="ag-theme-alpine"
              style={{
                height: height * 0.76,
                padding: "5px 15px 15px 15px",
                fontSize: "12px",
              }}
            >
              <AgGridComponent
                rowData={appraisalList?.result || []}
                onFilterChanged={handleFilterChanged}
                onSortChanged={handleSortChanged}
                onGridReady={onGridReady}
                onRowDataChanged={handleRowDataChanged}
                onRowSelected={onRowSelectedHandle}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                totalPages={Math.ceil(
                  Number(appraisalList?.count) / appraisalApiParams?.pageSize
                )}
                frameworkComponents={{
                  btnCellRenderer: BtnCellRenderer,
                  ShowDetailsIconAppraisal: ShowDetailsIconAppraisal,
                  document: Document,
                  employee: Employee,
                  active_request: Activerequest,
                  enach: Enach,
                  document_verified: DocumentsVerified,
                  enach_generated: EnachGenerated,
                  esign: Esign,
                  duplicate_loan: DuplicateLoan,
                  active_loan: ActiveLoan,
                  is_loan_closed: LoanClosed,
                }}
              />
            </div>
            {appraisalList?.result?.length ? (
              <div
                style={{
                  display: "flex",
                  margin: "0px 15px 10px 15px",
                }}
              >
                {selectedRows?.length ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#000",
                    }}
                  >
                    Selected Rows: {selectedRows?.length || 0}
                  </div>
                ) : null}
                <Pagination
                  style={{
                    justifyContent: "right",
                    textAlign: "right",
                    marginLeft: "auto",
                  }}
                  current={appraisalApiParams?.page}
                  onChange={onChangePage}
                  total={Number(appraisalList?.count || 0)}
                  pageSize={appraisalApiParams?.pageSize}
                  defaultPageSize={appraisalApiParams?.page}
                  showSizeChanger={false}
                  showTotal={showTotal}
                />
              </div>
            ) : null}
          </Col>
        </Row>
        <Modal
          title="Parameters"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Row>
            <Col span={24}>
              <Form
                form={form}
                layout="vertical"
                initialValues={{ requiredMarkValue: requiredMark }}
                onValuesChange={onRequiredTypeChange}
                requiredMark={requiredMark}
              >
                <Form.Item
                  label="Processing Fee"
                  required
                  tooltip="This is a required field"
                >
                  <Input
                    placeholder="processing fee"
                    type="number"
                    value={processingFee}
                    onChange={(e) => {
                      setProcessingFee(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Interest Rate(%)"
                  required
                  tooltip="This is a required field"
                >
                  <Input
                    placeholder="rate"
                    type="number"
                    value={interestRate}
                    onChange={(e) => {
                      setInterestRate(e.target.value);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Type"
                  required
                  tooltip="This is a required field"
                >
                  <Input
                    placeholder="type"
                    type="text"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Approved Amount"
                  required
                  tooltip="This is a required field"
                >
                  <Input
                    placeholder="amount"
                    type="number"
                    value={approvedAmount}
                    onChange={(e) => {
                      setApprovedAmount(e.target.value);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Period in Weeks"
                  required
                  tooltip="This is a required field"
                >
                  <Input
                    placeholder="period"
                    type="number"
                    value={period}
                    onChange={(e) => {
                      setPeriod(e.target.value);
                    }}
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" style={{ backgroundColor: "#1B875A", border: 0 }} className="antd-button">Submit</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    </>
  );
}
