import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  Row,
  Col,
  Input,
  DatePicker,
  Button,
  Select,
  Switch,
  message,
  Modal,
  Card,
  Typography,
} from "antd";
import {
  enach,
  esign,
  nbfc,
  updateAllRowsConstant,
  updateLoanRequest,
  appraisalNotifications,
  EsignNotifications,
  createResourceAutopay,
  SanctionNotifications,
  sanction
} from "../../utitlities/request";
import {
  calculateApprovedAmount,
  calculateEmiFirstDate,
  calculateExpectedSalaryDate,
  calculateHealthPremium,
  calculateIrr,
  calculatePreEmiDate,
  calculatepreEmiPeriod,
  calculateProcesasingFee,
  calculateSalaryMultiplier,
  calculateTenure,
  DisbursedAmount,
  EmiAmount,
  Interest,
  preEmiInterest,
  TotalAmountToBeRepaid,
  numberWithCommas,
} from "../../utitlities/calculations";
import { HighlightOutlined, MehOutlined } from "@ant-design/icons";
import { capConfig } from "../../utitlities/calculationConfig";
import moment from "moment";
import { roles } from "./Constants";
import { MainReducer, Loan } from "../../types";
import { useLocation } from "react-router-dom";
import { fetchLoan, updateRequest } from "../../state/action-creators";
import _ from 'lodash';
import '../../styles/buttons.css';
const { Option } = Select;



interface LoanData {
  approval_date?: moment.Moment | null;
  repayment_schedule?: {
    role?: string;
    first_date?: moment.Moment | null;
    pre_emi_date?: moment.Moment | null;
  };
  processing_fee?: number | string;
  tenure?: string;
  nbfc?: {
    id?: string;
  };
}

const EMIEnachSingature: React.FC = () => {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const search = useLocation().search;
  const loan_id = new URLSearchParams(search).get("loan_id");
  const request_id = new URLSearchParams(search).get("request_id");
  const dateFormat = "DD/MM/YYYY";

  const loan = useSelector((state: MainReducer) => {
    return state.loan.loan.body;
  });
  const employee = useSelector((state: MainReducer) => {
    return state.loan.employee.body;
  });
  const request = useSelector((state: MainReducer) => {
    return state.loan.loanRequest.body;
  });
  const productsstatus = useSelector((state: MainReducer) => { return state.loan.loan.status });

  const [loanData, setLoanData] = useState<any>({});
  const [_beforeEditloanData, setbeforeEditloanData] = useState<any>({});
  const [nbfcel, setNbfc] = useState([]);
  const [userrole, setUserRole] = useState("");
  const [enachloading, setEnachLoading] = useState(false);
  const [esignloading, setEsignLoading] = useState(false);
  const [sanctionloading, setSanctionLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleEnach, setIsModalVisibleEnach] = useState(false);

  const [health_prem, setHealthPrem] = useState(0);
  const [APR, setApr] = useState(0);
  const [expected_salary_date, setExpectedSalaryDate] = useState("");
  const [salaryMultiplier, setsalaryMultiplier] = useState(0);
  const [loan_amount, setLoanAmount] = useState(0);
  const [eSignData, setEsign] = useState<any>({});
  const [sanctionData, setsanctionData] = useState<any>({});
  const [eNachData, setEnach] = useState<any>({
    account_no: "",
    enach_url: "",
    source_id: "",
    status: "",
  });

  useEffect(() => {
    //@ts-ignore
    let user = JSON.parse(localStorage.getItem("user")) || " ";
    setUserRole(user["role"]);
    (async () => {
      let nbfcelements: any = await nbfc();
      setNbfc(nbfcelements);
      console.log(nbfcelements);
    })();
  }, []);

  useEffect(() => {
    console.log('Setting onRender loanData as follows:', loan);
    // In case, this is a loan in PROCESSING status, none of the loan calculations will be present yet.
    // So, we calculate the suggested values for each field, and show it on the UI.
    // Else we show whatever values have been fetched from the database...
    if (productsstatus && loan["enach"]) {
      setEnach(loan["enach"]);
    }
    if (productsstatus && loan["esign"]) {
      setEsign(loan["esign"]);
    }

    if (productsstatus && loan["sanction_letter"]) {
      setsanctionData(loan["sanction_letter"]);
    }
    if (!loan.saved_value) {
      console.log('Setting data from suggested values');
      const role: any = loan["repayment_schedule"]["role"] ? loan["repayment_schedule"]["role"] : "monthly";
      const salary: any = employee["salaries"];
      const experience = employee["experience"];

      const avgSalary = String(Number(employee["employment"]["salary"]));
      const _approvalDate = new Date();
      const approvalDate = _approvalDate.toISOString().split("T")[0];

      const nextDay = new Date(approvalDate);
      nextDay.setDate(_approvalDate.getDate() + 1);
      const expectedDisbursalDate = nextDay.toISOString().split("T")[0];

      const approvedAmount = calculateApprovedAmount(experience, avgSalary, request["amount_requested"], role, request.client_id);
      const tenure = calculateTenure(approvedAmount, avgSalary, role, request.client_id);
      const expectedSalaryDate = calculateExpectedSalaryDate(salary, role, request.client_id);
      const emiFirstDate = calculateEmiFirstDate(expectedSalaryDate, expectedDisbursalDate, role, employee["employment"]["cmp_id"]);
      const preemidate = calculatePreEmiDate(emiFirstDate, expectedDisbursalDate, role);
      const irr = calculateIrr(tenure, role, request.client_id) || 0;
      const multiplier = calculateSalaryMultiplier(approvedAmount, avgSalary, role, experience, request.client_id) || 0
      const preemiinterest = preEmiInterest(approvedAmount, Number(irr) / 100, calculatepreEmiPeriod(preemidate, expectedDisbursalDate));
      (async () => {
        const suggestedRequest = {
          repayment_schedule: {
            loan_id: Number(loan_id),
          },
          loan: {
            suggested_irr: Number(irr),
            suggested_multiplier: Number(multiplier)
          },
        };
        const result = await updateAllRowsConstant([{ ...suggestedRequest }]);
        console.log("suggested result", result)
      })();
      let healthPremium = calculateHealthPremium(approvedAmount, tenure);
      if (!loan["health_prem_opted"]) {
        healthPremium = 0
      }
      let loanAmount = approvedAmount + preemiinterest + Number(healthPremium);


      const procFee = calculateProcesasingFee(role, approvedAmount, request.client_id);
      const divideAmount = role === "weekly" ? 52 : 12;
      const installmentAmount = EmiAmount(Number(irr) / 100 / divideAmount, tenure, loanAmount * -1, 0, 0);
      const interest = Interest(tenure, Number(irr), loanAmount, installmentAmount);


      const amountToBeRepaid = TotalAmountToBeRepaid(loanAmount, interest);
      let disbursalAmount = DisbursedAmount(loanAmount, procFee ? procFee : 0, preemiinterest) - Number(healthPremium);
      setsalaryMultiplier(salaryMultiplier);
      setExpectedSalaryDate(expectedSalaryDate ? expectedSalaryDate : '0');
      let multiplyapr2 = role == 'weekly' ? 52 : 12;
      let apr = Number((APR2(tenure,-1 * installmentAmount,disbursalAmount,0,0,0.1) * multiplyapr2*100).toFixed(2));  
      setApr(apr)
      console.log("apr is",apr)
      console.log({
        ...loan,
        approval_date: approvalDate,
        expected_disbursal_date: expectedDisbursalDate,
        health_prem_opted: healthPremium,
        approved_amount: approvedAmount,
        tenure: tenure,
        irr: String(irr),
        processing_fee: procFee,
        total_amount_to_be_repaid: amountToBeRepaid,
        disbursal_amount: disbursalAmount,
        health_prem: healthPremium,
        interest_amount: interest,
        repayment_schedule: {
          ...loan.repayment_schedule,
          role: role,
          emi_amount: installmentAmount,
          installment_amt: installmentAmount,
          first_date: emiFirstDate,
          pre_emi_date: preemidate,
          pre_emi_interest: String(preemiinterest)
        }
      })
      setLoanData({
        ...loan,
        approval_date: approvalDate,
        expected_disbursal_date: expectedDisbursalDate,
        health_prem_opted: healthPremium,
        approved_amount: approvedAmount,
        tenure: tenure,
        irr: String(irr),
        processing_fee: procFee,
        total_amount_to_be_repaid: amountToBeRepaid,
        disbursal_amount: disbursalAmount,
        health_prem: healthPremium,
        interest_amount: interest,
        repayment_schedule: {
          ...loanData.repayment_schedule,
          role: role,
          emi_amount: installmentAmount,
          installment_amt: installmentAmount,
          first_date: emiFirstDate,
          pre_emi_date: preemidate,
          pre_emi_interest: String(preemiinterest)
        }
      });
    } else {
      setLoanData({ ...loan });
      setbeforeEditloanData({ ...loan });
      
      let multiplyapr2 = loan?.repayment_schedule?.role == 'weekly' ? 52 : 12;
      let apr = Number((APR2(loan.tenure,-1 * loan?.repayment_schedule?.emi_amount,loan?.disbursal_amount,0,0,0.1) * multiplyapr2*100).toFixed(2));  
      console.log("apr is",apr,loan)
      setApr(apr)
    }
  }, [loan, productsstatus]);

  useEffect(() => {
    console.log('re-render');
    const { health_prem_opted, approved_amount, tenure } = loanData;
    const experience = employee?.experience || "";
    const salaries = employee?.salaries;
    const avg_salary = Number(employee?.employment?.salary || 0);
    const role = loanData?.repayment_schedule?.role;
    const pre_emi_interest = loanData?.repayment_schedule?.pre_emi_interest;
    setsalaryMultiplier(
      Number(
        calculateSalaryMultiplier(approved_amount, avg_salary, role, experience)
      )
    );
    let health_prem1 = 0;
    if (health_prem_opted) {
      health_prem1 = Number(
        calculateHealthPremium(
          Number(approved_amount || 0),
          Number(tenure || 0)
        )
      );
    }
    setHealthPrem(health_prem1);

    const exp_salary = calculateExpectedSalaryDate(salaries, role, request.client_id);
    setExpectedSalaryDate(exp_salary || "");
    const _amount =
      Number(approved_amount || 0) +
      Number(pre_emi_interest || 0) +
      Number(health_prem1);
    setLoanAmount(_amount);

    let multiplyapr2 = role == 'weekly' ? 52 : 12;
    let apr = Number((APR2(loanData?.tenure,-1 * loanData?.repayment_schedule?.installment_amt ,loanData?.disbursal_amount,0,0,0.1) * multiplyapr2*100).toFixed(2));  
    console.log("apr is",apr,loanData)
    setApr(apr)
  }, [employee?.employment?.salary, employee?.experience, employee?.salaries, loanData]);

  const eNach = async () => {
    const result = await updateAllRowsScreen();
    if (result) {
      let data = { loan_ids: [loan_id] };
      setEnachLoading(true);
      const loans = await enach(data);
      setTimeout(() => {
        dispatch(fetchLoan(Number(loan_id)));
        setEnachLoading(false);
      }, 5000);
    }
  };

  const autoPay = async () => {
    const result = await updateAllRowsScreen();
    if (result) {
      let data = { loan_ids: [loan_id] };
      setEnachLoading(true);
      const loans = await createResourceAutopay(data);
      setTimeout(() => {
        dispatch(fetchLoan(Number(loan_id)));
        setEnachLoading(false);
      }, 5000);
    }
  };

  const eSign = async () => {
    const result = await updateAllRowsScreen();
    if (result) {
      let data = { loan_ids: [loan_id] };
      setEsignLoading(true);
      const loans = await esign(data);
      setTimeout(() => {
        dispatch(fetchLoan(Number(loan_id)));
        setEsignLoading(false);
      }, 5000);
    }
  };
  const APR2 = (
    periods: number,
    payment: number,
    present: number,
    future: number = 0,
    type: number = 0,
    guess: number = 0.01
  ): number => {
    const epsMax = 1e-10; // Maximum epsilon for iteration end
    const iterMax = 10;   // Maximum number of iterations
  
    let rate = guess;
    let y: number, f: number, y0: number, y1: number;
    let x0 = 0, x1 = rate;
    let i = 0;
  
    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
    } else {
      f = Math.exp(periods * Math.log(1 + rate));
      y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }
  
    y0 = present + payment * periods + future;
    y1 = present * Math.exp(periods * Math.log(1 + rate)) + payment * (1 / rate + type) * (Math.exp(periods * Math.log(1 + rate)) - 1) + future;
  
    while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
      rate = (y1 * x0 - y0 * x1) / (y1 - y0);
      x0 = x1;
      x1 = rate;
  
      if (Math.abs(rate) < epsMax) {
        y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
      } else {
        f = Math.exp(periods * Math.log(1 + rate));
        y = present * f + payment * (1 / rate + type) * (f - 1) + future;
      }
  
      y0 = y1;
      y1 = y;
      i++;
    }
  
    return rate;
  };
  // let loanAmt = Number(loanObject.approved_amount) + Number(loanObject.pre_emi_interest);
  // let dividend = loanObject.role == 'weekly' ? 5200 : 1200;
  // let multiplyapr2 = loanObject.role == 'weekly' ? 52 : 12;
  // let apr = Number((this.APR2(loanObject.tenure,-1 * loanObject.emi_amount,loanObject.disbursal_amount,0,0,0.1) * multiplyapr2*100).toFixed(2));  
  const Sanction = async () => {
    const result = await updateAllRowsScreen();
    if (result) {
      let data = { loan_ids: [loan_id] };
      setSanctionLoading(true);
      const loans = await sanction(data);
      setTimeout(() => {
        dispatch(fetchLoan(Number(loan_id)));
        setSanctionLoading(false);
      }, 5000);
    }
  };


  const calculateAllInputFeilds = (e: any) => {
    console.log('Inside change handler...');
    const { name, value, innerObj } = e.target;
    // on date change if value is empty reset other value
    if (name === "pre_emi_date" && !value) {
      setLoanData({
        ...loanData,
        repayment_schedule: {
          ...loanData.repayment_schedule,
          pre_emi_date: null,
          pre_emi_interest: null,
        },
      });
      return;
    } else if (name === "first_date" && !value) {
      setLoanData({
        ...loanData,
        repayment_schedule: {
          ...loanData.repayment_schedule,
          first_date: null,
          pre_emi_date: null,
          pre_emi_interest: null,
        },
      });
      return;
    } else if (name === "approval_date" && !value) {
      setLoanData({
        ...loanData,
        approval_date: null,
        repayment_schedule: {
          ...loanData.repayment_schedule,
          first_date: null,
          pre_emi_date: null,
          pre_emi_interest: null,
        },
      });
      return;
    }
    let _loanData = JSON.parse(JSON.stringify(loanData));
    if (innerObj) {
      _loanData[innerObj][name] = value;
    } else {
      _loanData[name] = value;
    }
    const { amount_requested } = request;
    let {
      approval_date,
      expected_disbursal_date,
      health_prem_opted,
      approved_amount,
      tenure,
      irr,
      processing_fee,
      interest_amount,
      total_amount_to_be_repaid,
      disbursal_amount,
    } = _loanData;
    let { role, first_date, pre_emi_date, pre_emi_interest, installment_amt } =
      _loanData?.repayment_schedule;
    let { salaries, experience } = employee;
    const avg_salary = Number(employee["employment"]["salary"] || 0);
    const cmp_id = employee["employment"]["cmp_id"];
    if (name === "role") {
      approval_date = moment().toDate();
      expected_disbursal_date = moment().add(1, 'days').toDate();
    }
    // on first_date change check validations
    if (name === "first_date") {
      const _date = new Date(value);
      const _expectedDisbursalDate = new Date(expected_disbursal_date);
      // Calculate the number of days between the chosen date and the approval date
      const daysDifference = Math.ceil(
        (_date.getTime() - _expectedDisbursalDate.getTime()) / (1000 * 3600 * 24)
      );
      if (daysDifference > 52) {
        message.warning("EMI date is too far from approval date");
        return;
      } else if (daysDifference < 20) {
        message.warning("EMI date is too close to approval date");
        return;
      }
    }
    if (name !== "approved_amount") {
      approved_amount = calculateApprovedAmount(
        experience,
        avg_salary,
        amount_requested,
        role,
        request.client_id
      );
    }
    if (name !== "tenure") {
      tenure = calculateTenure(approved_amount, avg_salary, role, request.client_id);
    }
    const exp_salary = calculateExpectedSalaryDate(salaries, role, request.client_id);
    if (name !== "first_date") {
      first_date = calculateEmiFirstDate(
        exp_salary,
        expected_disbursal_date,
        role,
        cmp_id
      );
    }
    if (name !== "pre_emi_date") {
      pre_emi_date = calculatePreEmiDate(first_date, expected_disbursal_date, role);
    }
    if (name !== "irr") {
      irr = calculateIrr(tenure || 0, role, request.client_id);
    }
    if (name !== "pre_emi_interest") {
      pre_emi_interest = preEmiInterest(
        Number(approved_amount || 0),
        Number(irr || 0) / 100,
        calculatepreEmiPeriod(pre_emi_date, expected_disbursal_date)
      );
    }
    let healthPremium = health_prem_opted
      ? calculateHealthPremium(
        Number(approved_amount || 0),
        Number(tenure || 0)
      )
      : 0;

    let ln_amt =
      calculateApprovedAmount(experience, avg_salary, amount_requested, role, request.client_id) +
      pre_emi_interest +
      Number(healthPremium);
    if (name !== "processing_fee") {
      processing_fee = calculateProcesasingFee(role, approved_amount || 0, request.client_id);
    }
    // @ts-ignore
    let divide_amount = role === "weekly" ? 52 : 12;
    if (name !== "installment_amt") {
      installment_amt = EmiAmount(
        irr / 100 / divide_amount,
        Number(tenure || 0),
        ln_amt * -1,
        0,
        0
      );
    }
    //@ts-ignore
    if (name !== "interest_amount") {
      interest_amount = Interest(
        Number(tenure || 0),
        Number(irr || 0),
        Number(ln_amt || 0),
        Number(installment_amt || 0)
      );
    }
    if (name !== "total_amount_to_be_repaid") {
      total_amount_to_be_repaid = TotalAmountToBeRepaid(
        Number(ln_amt || 0),
        Number(interest_amount || 0)
      );
    }
    if (name !== "disbursal_amount") {
      disbursal_amount =
        DisbursedAmount(
          Number(ln_amt || 0),
          Number(processing_fee || 0),
          Number(pre_emi_interest || 0)
        ) - Number(healthPremium);
    }
    setLoanData({
      ..._loanData,
      approval_date: approval_date,
      expected_disbursal_date: expected_disbursal_date,
      tenure: tenure,
      processing_fee: processing_fee,
      approved_amount: approved_amount,
      irr: irr,
      interest_amount: interest_amount,
      disbursal_amount: disbursal_amount,
      total_amount_to_be_repaid: total_amount_to_be_repaid,
      repayment_schedule: {
        ..._loanData?.repayment_schedule,
        role: role,
        first_date: first_date,
        pre_emi_date: pre_emi_date,
        installment_amt: installment_amt,
        pre_emi_interest: pre_emi_interest,
      },
    });
  };



  const onnbfcChangeHandler = (value: number) => {
    const _index = nbfcel?.findIndex((el: any) => el?.id === value);
    if (_index !== -1) {
      const selectedNBFC = nbfcel[_index];
      console.log({
        ...loanData,
        nbfc: selectedNBFC,
      });
      setLoanData({
        ...loanData,
        nbfc: selectedNBFC,
      });
    }
  };


  const onApprovalDateChangeHandler = (value: any) => {
    const newApprovalDate = new Date(value);
    console.log('Approval Date:', newApprovalDate);

    const expectedDisbursalDate = new Date(value);
    expectedDisbursalDate.setDate(expectedDisbursalDate.getDate() + 1);
    console.log('Expected Disbursal Date:', expectedDisbursalDate);

    const role = loanData?.repayment_schedule?.role;
    const newIRR = calculateIrr(Number(loanData.tenure), role, request.client_id);
    const divideAmount = loanData.role === "weekly" ? 52 : 12;
    const newEMIFirstDate = calculateEmiFirstDate(expected_salary_date, expectedDisbursalDate, role, employee["employment"]["cmp_id"]);
    // Calculate pre emi date
    const newPreEMIDate = calculatePreEmiDate(newEMIFirstDate, expectedDisbursalDate, role);

    const newPreEMIPeriod = calculatepreEmiPeriod(newPreEMIDate, expectedDisbursalDate);
    console.log('Approval Date Pre EMI Period:', newPreEMIPeriod);
    console.log('Approval Date Pre EMI Args:', Number(loanData.approved_amount), Number(newIRR) / 100, newPreEMIPeriod)
    const newPreEMIInterest = preEmiInterest(Number(loanData.approved_amount), Number(newIRR) / 100, newPreEMIPeriod);
    const newLoanAmount = String(Number(loanData.approved_amount) + newPreEMIInterest + Number(health_prem));
    const newDisbursalAmount = DisbursedAmount(Number(newLoanAmount), Number(loanData.processing_fee), newPreEMIInterest) - Number(health_prem);
    const newInstallmentAmount = EmiAmount(Number(newIRR) / 100 / divideAmount, Number(loanData.tenure), Number(newLoanAmount) * -1, 0, 0);
    const newInterest = Interest(Number(loanData.tenure), Number(loanData.irr), Number(newLoanAmount), Number(newInstallmentAmount));
    const newAmountToBeRepaid = TotalAmountToBeRepaid(Number(newLoanAmount), Number(newInterest));
    console.log("New Total Repaid:", newAmountToBeRepaid)

    console.log('Setting new pre emi date');
    setLoanAmount(Number(newLoanAmount));
    setLoanData({
      ...loanData,
      approval_date: newApprovalDate,
      expected_disbursal_date: expectedDisbursalDate,
      irr: String(newIRR),
      disbursal_amount: newDisbursalAmount,
      total_amount_to_be_repaid: newAmountToBeRepaid,
      interest_amount: newInterest,
      repayment_schedule: {
        ...loanData?.repayment_schedule,
        first_date: newEMIFirstDate,
        pre_emi_date: newPreEMIDate,
        installment_amt: newInstallmentAmount,
        pre_emi_interest: String(newPreEMIInterest)
      },
    });
  }

  const onExpectedDisbursalDateChangeHandler = (value: any) => {
    const newExpectedDisbursalDate = new Date(value);
    console.log('New Expected Disbursal  Date:', newExpectedDisbursalDate);



    const role = loanData?.repayment_schedule?.role;
    const newIRR = calculateIrr(Number(loanData.tenure), role, request.client_id);
    const divideAmount = loanData.role === "weekly" ? 52 : 12;
    const newEMIFirstDate = calculateEmiFirstDate(expected_salary_date, newExpectedDisbursalDate, role, employee["employment"]["cmp_id"]);
    // Calculate pre emi date
    const newPreEMIDate = calculatePreEmiDate(newEMIFirstDate, newExpectedDisbursalDate, role);

    const newPreEMIPeriod = calculatepreEmiPeriod(newPreEMIDate, newExpectedDisbursalDate);
    console.log('Approval Date Pre EMI Period:', newPreEMIPeriod);
    console.log('Approval Date Pre EMI Args:', Number(loanData.approved_amount), Number(newIRR) / 100, newPreEMIPeriod)
    const newPreEMIInterest = preEmiInterest(Number(loanData.approved_amount), Number(newIRR) / 100, newPreEMIPeriod);
    const newLoanAmount = String(Number(loanData.approved_amount) + newPreEMIInterest + Number(health_prem));
    const newDisbursalAmount = DisbursedAmount(Number(newLoanAmount), Number(loanData.processing_fee), newPreEMIInterest) - Number(health_prem);
    const newInstallmentAmount = EmiAmount(Number(newIRR) / 100 / divideAmount, Number(loanData.tenure), Number(newLoanAmount) * -1, 0, 0);
    const newInterest = Interest(Number(loanData.tenure), Number(loanData.irr), Number(newLoanAmount), Number(newInstallmentAmount));
    const newAmountToBeRepaid = TotalAmountToBeRepaid(Number(newLoanAmount), Number(newInterest));
    console.log("New Total Repaid:", newAmountToBeRepaid)

    console.log('Setting new pre emi date');
    setLoanAmount(Number(newLoanAmount));
    setLoanData({
      ...loanData,
      expected_disbursal_date: newExpectedDisbursalDate,
      irr: String(newIRR),
      disbursal_amount: newDisbursalAmount,
      total_amount_to_be_repaid: newAmountToBeRepaid,
      interest_amount: newInterest,
      repayment_schedule: {
        ...loanData?.repayment_schedule,
        first_date: newEMIFirstDate,
        pre_emi_date: newPreEMIDate,
        installment_amt: newInstallmentAmount,
        pre_emi_interest: String(newPreEMIInterest)
      },
    });
  }

  const onTenureChangeHandler = (e: any) => {
    console.log('Inside tenure change handler')
    const newTenure = Number(e.target.value)
    const role = loanData?.repayment_schedule?.role;
    console.log("COMPANY ID:", request.client_id)
    const newIRR = calculateIrr(Number(newTenure), role, request.client_id);


    const divideAmount = loanData.role === "weekly" ? 52 : 12;



    const preEMIPeriod = calculatepreEmiPeriod(loanData.repayment_schedule.pre_emi_date, loanData.expected_disbursal_date);
    console.log('Tenure Pre Emi period:', preEMIPeriod);
    console.log(loanData.pre_emi_date, loanData.expected_disbursal_date)
    console.log('Tenure IRR Args:', Number(loanData.approved_amount || 0), Number(newIRR || 0) / 100, preEMIPeriod);
    const newPreEMIInterest = preEmiInterest(Number(loanData.approved_amount || 0), Number(newIRR || 0) / 100, preEMIPeriod);
    console.log('Pre EMI Interest:', newPreEMIInterest)
    let newHealthPremium = 0;
    if (loanData.health_prem_opted) {
      newHealthPremium = calculateHealthPremium(Number(loanData.approved_amount), Number(newTenure));

    }
    const newLoanAmount = String(Number(loanData.approved_amount) + newPreEMIInterest + Number(newHealthPremium));
    const newProcFee = calculateProcesasingFee(role, loanData.approved_amount || 0, request.client_id);
    const newDisbursalAmount = DisbursedAmount(Number(newLoanAmount), Number(newProcFee), newPreEMIInterest) - Number(newHealthPremium);
    const newInstallmentAmount = EmiAmount(Number(newIRR) / 100 / divideAmount, Number(newTenure), Number(newLoanAmount) * -1, 0, 0);
    const newInterest = Interest(Number(newTenure), Number(newIRR), Number(newLoanAmount), Number(newInstallmentAmount));
    const newAmountToBeRepaid = TotalAmountToBeRepaid(Number(newLoanAmount), Number(newInterest));


    setLoanData({
      ...loanData,
      tenure: newTenure,
      irr: String(newIRR),
      processing_fee: newProcFee,
      health_prem: newHealthPremium,
      disbursal_amount: newDisbursalAmount,
      interest_amount: newInterest,
      total_amount_to_be_repaid: newAmountToBeRepaid,
      repayment_schedule: {
        ...loanData?.repayment_schedule,
        pre_emi_interest: String(newPreEMIInterest),
        installment_amt: newInstallmentAmount
      },
    });

  }

  const onIRRChangeHandler = (e: any) => {

    const role = loanData?.repayment_schedule?.role;
    const newIRR = Number(e.target.value);
    let maxIrr = capConfig[loanData.tenure].MaxInterestRate
    console.log("max irr",maxIrr)

    if (newIRR > maxIrr) {
      message.warning("IRR exceeds the max value for this tenure");
      return;
    }
    const divideAmount = loanData.role === "weekly" ? 52 : 12;



    const preEMIPeriod = calculatepreEmiPeriod(loanData.repayment_schedule.pre_emi_date, loanData.expected_disbursal_date);
    console.log(loanData.pre_emi_date, loanData.expected_disbursal_date)
    const newPreEMIInterest = preEmiInterest(Number(loanData.approved_amount || 0), Number(newIRR || 0) / 100, preEMIPeriod);
    console.log('Pre EMI Interest:', newPreEMIInterest)
    let newHealthPremium = 0;
    if (loanData.health_prem_opted) {
      newHealthPremium = calculateHealthPremium(Number(loanData.approved_amount), Number(loanData.tenure));

    }
    const newLoanAmount = String(Number(loanData.approved_amount) + newPreEMIInterest + Number(newHealthPremium));
    const newProcFee = calculateProcesasingFee(role, loanData.approved_amount || 0, request.client_id);
    const newDisbursalAmount = DisbursedAmount(Number(newLoanAmount), Number(newProcFee), newPreEMIInterest) - Number(newHealthPremium);
    const newInstallmentAmount = EmiAmount(Number(newIRR) / 100 / divideAmount, Number(loanData.tenure), Number(newLoanAmount) * -1, 0, 0);
    const newInterest = Interest(Number(loanData.tenure), Number(newIRR), Number(newLoanAmount), Number(newInstallmentAmount));
    const newAmountToBeRepaid = TotalAmountToBeRepaid(Number(newLoanAmount), Number(newInterest));


    setLoanData({
      ...loanData,
      irr: String(newIRR),
      processing_fee: newProcFee,
      health_prem: newHealthPremium,
      disbursal_amount: newDisbursalAmount,
      interest_amount: newInterest,
      total_amount_to_be_repaid: newAmountToBeRepaid,
      repayment_schedule: {
        ...loanData?.repayment_schedule,
        pre_emi_interest: String(newPreEMIInterest)
      },
    });

  }

  const onProcFreeChangeHandler = (e: any) => {
    const newProcFee = Number(e.target.value)
    let maxProcfeePercentage = capConfig[loanData.tenure].MaxProcessingFees;
    console.log("proc fees per",maxProcfeePercentage)
    let maxProcfee = loanData.approved_amount * (maxProcfeePercentage / 100)
    console.log("proc fees per val",maxProcfee)
    if (newProcFee > maxProcfee) {
      message.warning("Processing Fee exceeds the max value for this tenure");
      return;
    }
    const newDisbursalAmount = DisbursedAmount(Number(loan_amount), Number(newProcFee), loanData.repayment_schedule.pre_emi_interest) - Number(loanData.health_prem);

    setLoanData({
      ...loanData,
      processing_fee: newProcFee,
      disbursal_amount: newDisbursalAmount
    });
  }

  const onHealthToggleChangeHandler = (checked: boolean) => {
    const newHealthPremOpted = checked;
    let newHealthPremium = 0;
    const approvedAmount = loanData.approved_amount;
    const role = loanData?.repayment_schedule?.role;
    const preEMIInterest = Number(loanData.repayment_schedule.pre_emi_interest);


    const divideAmount = loanData.role === "weekly" ? 52 : 12;
    const tenure = Number(loanData.tenure);
    if (newHealthPremOpted) {
      newHealthPremium = calculateHealthPremium(Number(loanData.approved_amount), Number(loanData.tenure));

    }
    const newLoanAmount = String(Number(approvedAmount) + preEMIInterest + Number(newHealthPremium));
    const newDisbursalAmount = DisbursedAmount(Number(newLoanAmount), Number(loanData.processing_fee), preEMIInterest) - Number(newHealthPremium);
    const newInstallmentAmount = EmiAmount(Number(loanData.irr) / 100 / divideAmount, Number(tenure), Number(newLoanAmount) * -1, 0, 0);
    const newInterest = Interest(Number(tenure), Number(loanData.irr), Number(newLoanAmount), Number(newInstallmentAmount));
    const newAmountToBeRepaid = TotalAmountToBeRepaid(Number(newLoanAmount), Number(newInterest));

    setLoanAmount(Number(newLoanAmount));

    console.log(
      {
        ...loanData,
        interest_amount: newInterest,
        health_prem_opted: newHealthPremOpted,
        health_prem: newHealthPremium,
        disbursal_amount: newDisbursalAmount,
        total_amount_to_be_repaid: newAmountToBeRepaid,
        repayment_schedule: {
          ...loanData?.repayment_schedule,
          installment_amt: newInstallmentAmount
        }

      }
    )
    setLoanData({
      ...loanData,
      health_prem_opted: newHealthPremOpted,
      health_prem: newHealthPremium,
      disbursal_amount: newDisbursalAmount,
      interest_amount: newInterest,
      total_amount_to_be_repaid: newAmountToBeRepaid,
      repayment_schedule: {
        ...loanData?.repayment_schedule,
        installment_amt: newInstallmentAmount
      }
    });
  }


  const onEMIFirstDateChangeHandler = (value: any) => {
    console.log('Inside first date change handler');
    if (!value) return;
    const _date = new Date(value);
    let divideAmount = loanData.role === "weekly" ? 52 : 12;
    const approvalDate = new Date(loanData?.approval_date);
    const expectedDisbursalDate = new Date(loanData?.expected_disbursal_date);
    // Calculate the number of days between the chosen date and the approval date
    const daysDifference = Math.ceil(
      (_date.getTime() - expectedDisbursalDate.getTime()) / (1000 * 3600 * 24)
    );
    if (daysDifference > 52) {
      message.warning("EMI date is too far from approval date");
    } else if (daysDifference < 20) {
      message.warning("EMI date is too close to approval date");
    } else {
      const role = loanData?.repayment_schedule?.role;

      // Calculate pre emi date
      const newPreEMIDate = calculatePreEmiDate(
        value,
        loanData?.expected_disbursal_date,
        role
      );

      const newPreEMIPeriod = calculatepreEmiPeriod(newPreEMIDate, expectedDisbursalDate);
      console.log(newPreEMIPeriod);
      const newPreEMIInterest = preEmiInterest(Number(loanData.approved_amount), Number(loanData.irr) / 100, newPreEMIPeriod);
      const newLoanAmount = String(Number(loanData.approved_amount) + newPreEMIInterest + Number(health_prem));
      const newDisbursalAmount = DisbursedAmount(Number(newLoanAmount), Number(loanData.processing_fee), newPreEMIInterest) - Number(health_prem);
      const newInstallmentAmount = EmiAmount(Number(loanData.irr) / 100 / divideAmount, Number(loanData.tenure), Number(newLoanAmount) * -1, 0, 0);
      const newInterest = Interest(Number(loanData.tenure), Number(loanData.irr), Number(newLoanAmount), Number(newInstallmentAmount));
      const newAmountToBeRepaid = TotalAmountToBeRepaid(Number(newLoanAmount), Number(newInterest));
      console.log("New Total Repaid:", newAmountToBeRepaid)

      console.log('Setting new pre emi date');
      setLoanAmount(Number(newLoanAmount));
      setLoanData({
        ...loanData,
        disbursal_amount: newDisbursalAmount,
        total_amount_to_be_repaid: newAmountToBeRepaid,
        interest_amount: newInterest,
        repayment_schedule: {
          ...loanData?.repayment_schedule,
          first_date: value,
          pre_emi_date: newPreEMIDate,
          installment_amt: newInstallmentAmount,
          pre_emi_interest: String(newPreEMIInterest)
        },
      });
    }
  };


  const onApprovedAmountChangedHandler = (e: any) => {
    console.log('Inside onApprovedAmountChangedHandler...', e);
    const role = loanData?.repayment_schedule?.role;
    const average_salary = employee?.employment?.salary || 0;
    const divideAmount = loanData.role === "weekly" ? 52 : 12;
    const newApprovedAmount = Number(e.target.value);
    const newSalaryMultiplier = calculateSalaryMultiplier(newApprovedAmount, average_salary, role, loanData.experience);
    console.log("new salary multiper", newSalaryMultiplier)
    const newTenure = calculateTenure(Number(newApprovedAmount), Number(average_salary), role, request.client_id);
    const preEMIPeriod = calculatepreEmiPeriod(loanData.repayment_schedule.pre_emi_date, loanData.expected_disbursal_date);
    const newPreEMIInterest = preEmiInterest(Number(newApprovedAmount || 0), Number(loanData.irr || 0) / 100, preEMIPeriod);

    let newHealthPremium = 0;
    if (loanData.health_prem_opted) {
      newHealthPremium = calculateHealthPremium(Number(newApprovedAmount), Number(newTenure));

    }

    const newLoanAmount = String(Number(newApprovedAmount) + newPreEMIInterest + Number(newHealthPremium));
    const newProcFee = calculateProcesasingFee(role, newApprovedAmount || 0, request.client_id);
    const newDisbursalAmount = DisbursedAmount(Number(newLoanAmount), Number(newProcFee), newPreEMIInterest) - Number(newHealthPremium);
    const newInstallmentAmount = EmiAmount(Number(loanData.irr) / 100 / divideAmount, Number(newTenure), Number(newLoanAmount) * -1, 0, 0);
    const newInterest = Interest(Number(newTenure), Number(loanData.irr), Number(newLoanAmount), Number(newInstallmentAmount));
    const newAmountToBeRepaid = TotalAmountToBeRepaid(Number(newLoanAmount), Number(newInterest));


    setsalaryMultiplier(newSalaryMultiplier);
    setLoanAmount(Number(newLoanAmount));

    setLoanData({
      ...loanData,
      tenure: newTenure,
      processing_fee: newProcFee,
      approved_amount: newApprovedAmount,
      health_prem: newHealthPremium,
      disbursal_amount: newDisbursalAmount,
      interest_amount: newInterest,
      total_amount_to_be_repaid: newAmountToBeRepaid,
      repayment_schedule: {
        ...loanData?.repayment_schedule,
        pre_emi_interest: String(newPreEMIInterest),
        installment_amt: newInstallmentAmount
      },
    });
  };


  const validateAllFields = async () => {
    const {
      interest_amount,
      approved_amount,
      tenure,
      irr,
      processing_fee,
      disbursal_amount,
      total_amount_to_be_repaid,
    } = loanData;
    const installment_amt = loanData?.repayment_schedule?.installment_amt;
    const pre_emi_interest = loanData?.repayment_schedule?.pre_emi_interest;
    let fields_for_number: any = {
      "Installment Amount": Number(installment_amt),
      "Emi Amount": Number(installment_amt),
      "Pre Emi Interest": Number(pre_emi_interest),
      Interest: Number(interest_amount),
      "Approved Amount": Number(approved_amount),
      Tenure: Number(tenure),
      Irr: Number(irr),
      "Processing Fee": Number(processing_fee),
      "Disbursal Amount": Number(disbursal_amount),
      "Total Amount to be Repaid": Number(total_amount_to_be_repaid),
      "Health Premium": Number(health_prem),
    };
    let field = "";
    Object.keys(fields_for_number).forEach((key: any) => {
      if (isNaN(fields_for_number[key])) {
        field = key;
      }
    });
    if (field.length > 0) {
      message.error(`${field} must be a numeric value`);
      return false;
    } else {
      return true;
    }
  };

  const getChangedFields = () => {
    const changedFields: Record<string, any> = {};

    const fieldsToCompare = [
      'interest_amount',
      'approved_amount',
      'tenure',
      'irr',
      'processing_fee',
      'disbursal_amount',
      'total_amount_to_be_repaid',
      'approval_date',
      'expected_disbursal_date',
      'health_prem_opted',
      'repayment_schedule.pre_emi_date',
      'repayment_schedule.first_date',
      'repayment_schedule.role',
      'repayment_schedule.installment_amt',
      'repayment_schedule.pre_emi_interest'
    ];

    fieldsToCompare.forEach(field => {
      const [parentKey, childKey] = field.split('.');
      if (childKey) {
        if (!_.isEqual(loanData[parentKey]?.[childKey], _beforeEditloanData[parentKey]?.[childKey])) {
          changedFields[String(childKey)] = loanData[parentKey][childKey];
        }
      } else {
        if (!_.isEqual(loanData[field], _beforeEditloanData[field])) {
          changedFields[field] = loanData[field];
        }
      }
    });

    console.log('Changed Fields:', changedFields);

    return changedFields;
  }

  const updateAllRowsScreen = async () => {
    const changedFields = getChangedFields();
    const fields = await validateAllFields();
    if (fields) {
      const {
        interest_amount,
        approved_amount,
        tenure,
        irr,
        processing_fee,
        disbursal_amount,
        total_amount_to_be_repaid,
        approval_date,
        expected_disbursal_date,
        health_prem_opted,
      } = loanData;
      const {
        pre_emi_date,
        first_date,
        role,
        installment_amt,
        pre_emi_interest,
      } = loanData?.repayment_schedule;

      const request = {
        repayment_schedule: {
          installment_amt: Number(installment_amt),
          installment_count: Number(tenure),
          first_date: first_date,
          role: role,
          emi_amount: Number(installment_amt),
          pre_emi_interest: Number(pre_emi_interest),
          pre_emi_date: pre_emi_date,
          interest: Number(interest_amount),
          loan_id: Number(loan_id),
        },
        loan: {
          approval_date: approval_date,
          expected_disbursal_date: expected_disbursal_date,
          approved_amount: Number(approved_amount),
          tenure: Number(tenure),
          irr: Number(irr),
          processing_fee: Number(processing_fee),
          interest_amount: Number(interest_amount),
          disbursal_amount: Number(disbursal_amount),
          total_amount_to_be_repaid: Number(total_amount_to_be_repaid),
          nbfc_id: loanData?.nbfc?.id,
          health_prem: Number(health_prem),
          saved_value: true,
          health_prem_opted: health_prem_opted,
        },
        change_fields: changedFields,
        request_id: Number(request_id),
      };
      const result = await updateAllRowsConstant([{ ...request }]);

      if (result["statusCode"] == 200) {
        message.success("updated data");
        return true;
      } else {
        message.error("could not update data");
        return false;
      }
    }
  };

  const appraisalNotify = async () => {
    let emandate_status = ['mandate activated',
      'pending_bank_approval',
      'source submitted',
      'mandate acknowledged'];

    if (!emandate_status.includes(eNachData["status"])) {
      if (eNachData["enach_url"]) {
        let data = {
          loan_ids: [loan_id],
        };
        const loans = await appraisalNotifications(data);
      }
      else {
        message.error("Enach not generated");
      }
    }
    else {
      message.error("E-mandate is already activated");

    }
  };

  const EsignNotify = async () => {
    if (eSignData["status"] !== "signed") {
      if (eSignData["status"] == "processed" || eSignData["status"] == "esignature sent") {
        let data = {
          loan_ids: [loan_id],
        };
        const loans = await EsignNotifications(data)
      }
      else {
        message.error("Esignature not generated");

      }
    }
    else {
      message.error('Esignature already signed');
    }




  };

  const SanctionNotify = async () => {
    if (sanctionData["status"] !== "signed") {
      if (sanctionData["status"] == "processed" || sanctionData["status"] == "esignature sent") {
        let data = {
          loan_ids: [loan_id],
        };
        const loans = await SanctionNotifications(data)
      }
      else {
        message.error("Sanction not generated");

      }
    }
    else {
      message.error('Sanction already signed');
    }




  };


  const isSalary = Number(employee["employment"]["salary"] || 0) > 0;
  return (
    <div className="emp-info-form">
      <div className="emi-head-action">
        <div className="title">EMI Enach ESingature</div>
        <Button type="text" className="save-btn antd-button" onClick={() => updateAllRowsScreen()}>
          Save Data
        </Button>
      </div>
      <Divider style={{ borderColor: "#E5E7EA", margin: "20px 0px" }} />
      {isSalary && (userrole == "appraisal" || userrole == "admin" || userrole == "super_admin") ? (
        <>
          <div className="form01">
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Approval Date</div>
                <DatePicker
                  style={{ width: "100%" }}
                  name="approval_date"
                  value={
                    loanData?.approval_date
                      ? moment(loanData?.approval_date)
                      : null
                  }
                  format={dateFormat}
                  onChange={onApprovalDateChangeHandler}
                />
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">APR</div>
                <div className="value">{APR || '-'}</div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Expected Disbursal Date</div>
                <DatePicker
                  style={{ width: "100%" }}
                  name="expected_disbursal_date"
                  value={
                    loanData?.expected_disbursal_date
                      ? moment(loanData?.expected_disbursal_date)
                      : null
                  }
                  format={dateFormat}
                  onChange={onExpectedDisbursalDateChangeHandler}
                />
              </Col>

              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Period</div>
                <Select
                  style={{ width: "100%" }}
                  value={loanData?.repayment_schedule?.role}
                  onChange={(value) => {
                    calculateAllInputFeilds({
                      target: {
                        value,
                        name: "role",
                        innerObj: "repayment_schedule",
                      },
                    });
                  }}
                >
                  {
                    // @ts-ignore
                    roles.map((role: any) => {
                      return (
                        <Option key={role} value={role}>
                          {role}
                        </Option>
                      );
                    })
                  }
                </Select>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">EMI First Date</div>
                <DatePicker
                  style={{ width: "100%" }}
                  name="first_date"
                  value={
                    loanData?.repayment_schedule?.first_date
                      ? moment(loanData?.repayment_schedule?.first_date)
                      : null
                  }
                  format={dateFormat}
                  onChange={onEMIFirstDateChangeHandler}
                />
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Pre EMI Date</div>
                <DatePicker
                  style={{ width: "100%" }}
                  name="pre_emi_date"
                  value={
                    loanData?.repayment_schedule?.pre_emi_date
                      ? moment(loanData?.repayment_schedule?.pre_emi_date)
                      : null
                  }
                  format={dateFormat}
                  inputReadOnly
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Approved Amount</div>
                <Input
                  name="approved_amount"
                  value={loanData?.approved_amount}
                  onChange={onApprovedAmountChangedHandler}
                />
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Processing Fee</div>
                <Input
                  type="number"
                  name="processing_fee"
                  value={loanData?.processing_fee}
                  onChange={onProcFreeChangeHandler}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Tenure</div>
                <Input
                  type="number"
                  name="tenure"
                  value={loanData?.tenure}
                  onChange={onTenureChangeHandler}
                />
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">NBFC</div>
                <Select
                  style={{ width: "100%" }}
                  defaultValue={nbfcel.length ? nbfcel[nbfcel.length - 1]['id'] : undefined}
                  value={loanData?.nbfc?.id}
                  onChange={onnbfcChangeHandler}
                >
                  {
                    // @ts-ignore
                    nbfcel?.length &&
                    nbfcel?.map((element: any) => {
                      return (
                        <Option key={element?.id} value={element?.id}>
                          {element?.name}
                        </Option>
                      );
                    })
                  }
                </Select>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">IRR</div>
                <Input
                  type="number"
                  name="irr"
                  value={loanData?.irr}
                  onChange={onIRRChangeHandler}
                />
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Installment Amount</div>
                <Input
                  name="installment_amt"
                  value={loanData?.repayment_schedule?.installment_amt}
                  onChange={(e) => {
                    const { value } = e?.target;
                    calculateAllInputFeilds({
                      target: {
                        name: "installment_amt",
                        value: value,
                        innerObj: "repayment_schedule",
                      },
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Interest</div>
                <Input
                  name="interest_amount"
                  value={loanData?.interest_amount}
                  onChange={calculateAllInputFeilds}
                />
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Max EMI</div>
                <div className="value">{employee?.emi_max || '-'}</div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Pre EMI Interest</div>
                <Input
                  name="pre_emi_interest"
                  value={loanData?.repayment_schedule?.pre_emi_interest}
                />
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Total Amount to be Repaid</div>
                <Input
                  name="total_amount_to_be_repaid"
                  value={loanData?.total_amount_to_be_repaid}
                  onChange={calculateAllInputFeilds}
                />
              </Col>

            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Toggle Health Premium</div>
                <Switch
                  checked={loanData?.health_prem_opted}
                  onChange={onHealthToggleChangeHandler}
                />
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Disbursal Amount</div>
                <Input
                  name="disbursal_amount"
                  value={loanData?.disbursal_amount}
                  onChange={calculateAllInputFeilds}
                />
              </Col>

            </Row>
            <Row
              gutter={24}
              style={{
                margin: "10px 0px 20px 0px",
              }}
            >
              <Button type="primary" className="enach-btn antd-button" ghost onClick={() => { appraisalNotify(); }}>
                Enach Notifications
              </Button>
              <Button type="primary" className="enach-btn antd-button" ghost onClick={() => { EsignNotify(); }}>
                Esign Notifications
              </Button>
              <Button
                type="primary"
                className="enach-btn antd-button"
                loading={enachloading}
                onClick={() => {
                  if (loanData["enach"] && loanData["enach"]["enach_url"]) {
                    setIsModalVisibleEnach(true);
                  } else {
                    eNach();
                  }
                }}
                ghost
              >
                Generate Enach
              </Button>
              <Button
                type="primary"
                className="enach-btn antd-button"
                loading={enachloading}
                onClick={() => autoPay()}
                ghost
              >
                Generate UPI Autopay
              </Button>

              <Button
                type="primary"
                className="enach-btn antd-button"
                loading={esignloading}
                onClick={() => {
                  eSign();
                }}
                ghost
              >
                Generate Esign
              </Button>
            </Row>
            <Row
              gutter={24}
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: "10px 0px 20px 0px",
              }}>
              <Button type="primary" className="enach-btn antd-button" ghost onClick={() => { SanctionNotify(); }}>
                Sanction Letter Notification
              </Button>
              <Button type="primary" className="enach-btn antd-button" loading={sanctionloading} ghost onClick={() => { Sanction(); }}>
                Generate Sanction Letter
              </Button>
            </Row>
          </div>
          <Divider
            orientation="left"
            style={{ borderColor: "#E5E7EA", margin: "20px 0px" }}
          >
            Generated Enach/Esign Data
          </Divider>
          <div className="form01">
            <Row gutter={24} style={{ marginBottom: "20px" }}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Loan Id</div>
                <div className="value">{loanData.id}</div>
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Request Id</div>
                <div className="value">{loanData.request_id}</div>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: "20px" }}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Expected Salary Day</div>
                <div className="value">{expected_salary_date.split("-")[2] || "NA"}</div>
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Average Salary</div>
                <div className="value">
                  {employee?.employment?.salary !== null &&
                    employee?.employment?.salary !== undefined
                    ? numberWithCommas(Number(employee?.employment?.salary))
                    : 0}
                </div>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: "20px" }}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Salary Multiplier</div>
                <div className="value"> {salaryMultiplier}</div>
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Suggested Amount</div>
                <Text
                  style={{ fontSize: "14px" }}
                  editable={{
                    icon: <HighlightOutlined />,
                    tooltip: "click to edit text",
                    onChange: (val) => {
                      dispatch(updateRequest({
                        ...request,
                        suggested_amount_requested: val
                      }))
                    },
                  }}
                >
                  {request.suggested_amount_requested}
                </Text>
              </Col>

            </Row>
            <Row gutter={24} style={{ marginBottom: "20px" }}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Loan Amount</div>
                <div className="value">
                  {loan_amount !== null && loan_amount !== undefined
                    ? numberWithCommas(Number(loan_amount))
                    : 0}
                </div>
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Experience in months</div>
                <div className="value">
                  {Number(employee?.experience || 0).toFixed(1)}
                </div>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: "20px" }}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Health Premium</div>
                <div className="value">{health_prem}</div>
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Enach URL</div>
                <a
                  href={loanData?.enach?.enach_url}
                  target="_blank" // Opens the link in a new tab
                  rel="noopener noreferrer" // Recommended for security when using target="_blank"
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textDecoration: "underline",
                    color: "blue",
                  }}
                >
                  {loanData?.enach?.enach_url}
                </a>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: "20px" }}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Enach Source Id</div>
                <div className="value">{loanData?.enach?.source_id}</div>
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Enach UMRN</div>
                <div className="value">{loanData?.enach?.umrn}</div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Enach Mandate Id</div>
                <div className="value">{loanData?.enach?.mandate_id}</div>
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Enach Status</div>
                <div className="value">{loanData?.enach?.status}</div>
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Enach Amount</div>
                <div className="value">{loanData?.enach?.enach_amount}</div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Esign File</div>
                <div className="value">
                  <a href={loanData?.esign?.upload_url} target="_blank" rel="noopener noreferrer">
                    {loanData?.esign?.file_name}
                  </a>
                </div>
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Esign URL</div>
                <div className="value">
                  <a href={loanData?.esign?.sign_url} target="_blank" rel="noopener noreferrer">
                    {loanData?.esign?.sign_url}
                  </a>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Esign Status</div>
                <div className="value">{loanData?.esign?.status}</div>
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Mandate Type</div>
                <div className="value">{loan?.enach?.mandate_type}</div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Sanction Letter File</div>
                <div className="value">
                  <a href={loanData?.sanction_letter?.upload_url} target="_blank" rel="noopener noreferrer">
                    {loanData?.sanction_letter?.file_name}
                  </a>
                </div>
              </Col>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Sanction Letter URL</div>
                <div className="value">
                  <a href={loanData?.sanction_letter?.sign_url} target="_blank" rel="noopener noreferrer">
                    {loanData?.sanction_letter?.sign_url}
                  </a>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} xl={12} className="info-input-row">
                <div className="label">Sanction Letter Status</div>
                <div className="value">{loanData?.sanction_letter?.status}</div>
              </Col>
            </Row>
          </div>
          <Modal
            title="Alert"
            open={isModalVisible}
            onOk={() => {
              setIsModalVisible(false);
              eSign();
            }}
            onCancel={() => {
              setIsModalVisible(false);
            }}
          >
            <p>Do you want to proceed for generating esignature?</p>
          </Modal>

          <Modal
            title="eNach Already Exists!"
            open={isModalVisibleEnach}
            onOk={() => {
              setIsModalVisibleEnach(false);
              eNach();
            }}
            onCancel={() => {
              setIsModalVisibleEnach(false);
            }}
          >
            <p>
              Do you want to proceed with generating another eNach ? This will
              overwrite the previous eNach
            </p>
          </Modal>
        </>
      ) : (
        <Card>
          <p
            style={{
              textAlign: "center",
              fontFamily: "serif",
              fontSize: "25px",
            }}
          >
            {" "}
            Insufficient or Zero Salary Data
          </p>
          <MehOutlined style={{ fontSize: "100px", marginLeft: "46%" }} />
        </Card>
      )
      }
    </div >
  );
};
export default EMIEnachSingature;
